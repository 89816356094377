import React from 'react'

import Field from 'app/components/form/formBuilder/formField/Field'
import styles from 'app/components/form/formBuilder/formField/FormField.module.scss'
import { WppGrid } from 'buildingBlocks'
import { IFieldComment } from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import IForm from 'interfaces/form/IForm'
import { IReview } from 'interfaces/review/IReview'

interface IFormFieldProps {
  /** form element */
  form: IForm
  /** Review Object */
  review: IReview | null
  /** Is comment mode enabled*/
  enableCommentMode: boolean
  /** If user is project member*/
  isProjectMember: boolean
  /** Handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Handle field comment */
  handleComment: (field: IField, value: string) => void
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
}

/**
 * Build field
 * @param {object} props Component props
 * @param {IForm} props.form Form object
 * @param {IReview | null} props.review
 * @param {boolean} props.enableCommentMode
 * @param {boolean} props.isProjectMember
 * @param {(event: IFieldChangeEvent) => void} props.handleChange handle input field change event
 * @param {(field: IField, value: string) => void} props.handleComment Handle field comment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 */
const FormField: React.FC<IFormFieldProps> = (props: IFormFieldProps): React.ReactElement => {
  return (
    <WppGrid item all={24} className={styles.fieldsContainer}>
      {props.form.fields.map((field: IField) => (
        <Field key={field.id} field={field} {...props} isChildField={false} />
      ))}
    </WppGrid>
  )
}

export default FormField
