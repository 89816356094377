import * as _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/Form.module.scss'
import FormBuilder from 'app/components/form/formBuilder/FormBuilder'
import IdeaBriefSummaryTemplate from 'app/templates/IdeaBrief'
import { WppGrid, WppProgressIndicator, WppTypography } from 'buildingBlocks'
import IApp from 'interfaces/app/IApp'
import ICompletionRate from 'interfaces/app/ICompletionRate'
import ICategory from 'interfaces/category/ICategory'
import { IFieldComment } from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { IReview } from 'interfaces/review/IReview'

interface IFormProps {
  /** Questionnaire app */
  app: IApp
  /** Review Object */
  review: IReview | null
  /** Is comment mode enabled*/
  enableCommentMode: boolean
  /** ICategory interface */
  category: ICategory
  /** ICompletionRate interface */
  completionRate: ICompletionRate
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Handle field comment */
  handleComment: (field: IField, category: ICategory, value: string) => void
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
}

/**
 * Display form
 * @param {object} props
 * @param {ICategory} props.category
 * @param {IReview | null} props.review
 * @param {boolean} props.enableCommentMode
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 * @param {(field: IField, category: ICategory, value: string) => void} props.handleComment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 * @param {string} props.completionRate
 */
const Form: React.FC<IFormProps> = ({
  app,
  category,
  review,
  handleChange,
  enableCommentMode,
  handleComment,
  completionRate,
  setFormFieldComments,
}: IFormProps): React.ReactElement => {
  return (
    <div className={styles.container}>
      <WppGrid fullWidth container>
        <WppGrid item all={24}>
          <WppTypography className={styles.completionRate} tag="p" type="s-body">
            You completed <span className={styles.completionNumber}>{completionRate.completed}</span> of{' '}
            <span className={styles.completionNumber}>{completionRate.total}</span> fields.
          </WppTypography>
          <WppProgressIndicator variant="bar" value={completionRate.percentage} />
        </WppGrid>
        <WppGrid item xl={12} xxl={12} sm={24} md={16} all={22} className={styles.categoryContainer}>
          <WppTypography tag="h1" type="xl-heading">
            {category.name}
          </WppTypography>
          {!_.isEmpty(category.description) && (
            <WppTypography type="s-body" tag="p">
              <span
                dangerouslySetInnerHTML={{
                  __html: category.description,
                }}
              />
            </WppTypography>
          )}
        </WppGrid>
        <WppGrid item all={24} className={styles.formContainer}>
          <FormBuilder
            forms={category.forms}
            handleComment={(field: IField, value: string) => handleComment(field, category, value)}
            handleChange={(event: IFieldChangeEvent) =>
              handleChange({
                ...event,
                categoryId: category.id,
              })
            }
            isProjectMember={app.isProjectMember}
            setFormFieldComments={setFormFieldComments}
            review={review}
            enableCommentMode={enableCommentMode}
          />
        </WppGrid>
        <WppGrid item all={24}>
          {_.isEqual(app.appName, 'Idea Brief') && _.isEqual(category.name, 'The Summary') && (
            <IdeaBriefSummaryTemplate app={app} />
          )}
          {_.isEqual(app.appName, 'Idea Brief') && _.isEqual(category.name, 'Template') && (
            <IdeaBriefSummaryTemplate app={app} isEmpty />
          )}
        </WppGrid>
      </WppGrid>
    </div>
  )
}

export default Form
