import _ from 'lodash'

import { APP_EDITOR_PERMISSION } from 'config/enums'
import IPermission from 'interfaces/permission/IPermission'

export default class PermissionHelper {
  /**
   * Validate user permission for app editor
   * @param {IPermission[]} permissions
   * @returns {boolean}
   */
  public static hasAppEditor(permissions: IPermission[]): boolean {
    let isAppEditor = false
    permissions.forEach((permission: IPermission) => {
      permission.permissions.forEach((perm: string) => {
        if (_.isEqual(perm, APP_EDITOR_PERMISSION)) {
          isAppEditor = true
        }
      })
    })
    return isAppEditor
  }
}
