import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import styles from 'app/components/action/Action.module.scss'
import PdfViewer from 'app/components/action/pdfViewer'
import ReviewAction from 'app/components/review/action'
import CancelReview from 'app/components/review/cancelReview'
import SendToReview from 'app/components/review/sendToReview'
import { WppActionButton, WppButton, WppIconChatMessage } from 'buildingBlocks'
import { AppAction, QuestionnaireStatus, ToastMessageType } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import { IReview } from 'interfaces/review/IReview'
import { IReviewer } from 'interfaces/review/IReviewer'
import FormFieldHelper from 'utils/formField/FormFieldHelper'

const formFieldHelper = new FormFieldHelper()

interface IActionProps {
  /** IApp interface */
  app: IApp
  /** Is comment mode enabled*/
  enableCommentMode: boolean
  /** Handle app Submit/Save Progress/Cancel event*/
  handleAppAction: (appAction: string, callback: (message: ToastMessageType) => void, displayMessage?: boolean) => void
  /** Enable/disable comment mode*/
  handleCommentMode: () => void
  /** Validate app*/
  validateApp: () => boolean
  /** Active step*/
  activeStep: number
  /** Handle reviewer action*/
  handleReviewerAction: (statusValue: string, statusReason: string) => void
  /** Reviewers data */
  reviewers: IReviewer[]
  /** Review data */
  review: IReview | null
  /** Handle send to review action */
  handleSendToReview: (reviewer: IReviewer | null, category: ICategory) => void
  /** Handle cancel review action */
  handleCancelReview: (projectQuestionnaireId: string) => void
}

/**
 * App action
 * @param {object} props
 * @param {IApp[]} props.app
 * @param {boolean} props.enableCommentMode
 * @param {Function} props.validateApp
 * @param {Function} props.handleCommentMode
 * @param {Function} props.handleAppAction
 * @param {number} props.activeStep
 * @param {IReviewer[]} props.reviewers
 * @param {IReview} props.review
 * @param {(reviewer: IReviewer, category: ICategory) => void} props.handleSendToReview
 * @param {(projectQuestionnaireId: string) => void} props.handleCancelReview
 * @param {(statusValue: string, statusReason: string) => void} props.handleReviewerAction
 */
const Action: React.FC<IActionProps> = ({
  app,
  activeStep,
  reviewers,
  review,
  handleSendToReview,
  handleAppAction,
  handleReviewerAction,
  handleCancelReview,
  enableCommentMode,
  handleCommentMode,
  validateApp,
}: IActionProps): React.ReactElement => {
  const fieldsData = formFieldHelper.getFormFieldsObject(app.categories)
  const isAppEditor = app.isAppEditor && app.isProjectMember
  const isCommentMode = !_.isEqual(review?.statusValue, QuestionnaireStatus.APPROVED) && app.isProjectMember

  const isCancelReviewAction =
    review &&
    !review.isReviewer &&
    _.isEqual(review.statusValue, QuestionnaireStatus.SENT_FOR_APPROVAL) &&
    app.isProjectMember

  const isReviewAction =
    _.isEqual(review?.statusValue, QuestionnaireStatus.SENT_FOR_APPROVAL) && review?.isReviewer && app.isProjectMember

  const isSendToReviewAction =
    (_.isNull(review) || _.isEqual(review?.statusValue, QuestionnaireStatus.DRAFT)) && isAppEditor

  return (
    <>
      {_.isEqual(app.appName, 'Idea Brief') && (
        <CopyToClipboard
          text={`Brand: Coca-Cola Campaign for FIFA Women's World Cup 2027\nHosting countries: US and Mexico\nInsights: ${fieldsData['the insights']}\nAudiences: Players, National Fans, Superstar Fans, Coerced Companions, Extended Family, Nuclear Family, First Timers, City Citizens, Party Goers, Young Culturalist Couples, Weekend Socialites, Hall Passers, Sports Fans, Patriots\nSuggest ideas for each of the activation types: digital, live, product and retail activations has context menu`}
        >
          <WppButton id="btn-copy-campaign-details" className={styles.btnNextSubmit}>
            Copy campaign details
          </WppButton>
        </CopyToClipboard>
      )}
      <PdfViewer app={app} handleAppAction={handleAppAction} />
      {isCommentMode && (
        <WppActionButton
          className={classNames({ [styles.enableCommentMode]: enableCommentMode })}
          id="btn-comment-mode"
          variant="secondary"
          onClick={handleCommentMode}
        >
          <WppIconChatMessage color="var(--wpp-brand-color)" />
        </WppActionButton>
      )}
      {isCancelReviewAction && (
        <CancelReview
          handleCancelReview={() => handleCancelReview(review.projectQuestionnaireId)}
          reviewers={reviewers}
        />
      )}
      {isReviewAction && <ReviewAction handleReviewerAction={handleReviewerAction} />}
      {!_.isEqual(app.categories.length, activeStep) && isAppEditor && (
        <WppButton
          id="btn-save-progress"
          data-testid="btn-save-progress"
          onClick={() => handleAppAction(AppAction.SAVE_PROGRESS, () => {})}
          className={classNames(styles.btnSaveProgress)}
          size="s"
        >
          Save Progress
        </WppButton>
      )}
      {isSendToReviewAction && (
        <SendToReview
          reviewerEmail={review?.reviewerEmail ?? null}
          reviewers={reviewers}
          disabled={!(_.isEmpty(review?.statusReason) || app.isTouched)}
          handleSendToReview={(reviewer: IReviewer | null) => {
            handleSendToReview(reviewer, app.categories[activeStep - 1])
          }}
          validateApp={validateApp}
        />
      )}
      {_.isEqual(app.categories.length, activeStep) && isAppEditor && (
        <WppButton
          id="btn-submit"
          data-testid="btn-submit"
          onClick={() => handleAppAction(AppAction.SAVE_AND_EXIT, () => {})}
          className={styles.btnNextSubmit}
          size="s"
        >
          Save and Exit
        </WppButton>
      )}
    </>
  )
}

export default Action
