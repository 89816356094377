// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cyWIT{display:flex;align-items:center;justify-content:center;flex-direction:column;position:fixed;top:50%;transform:translate(-50%, -100%);left:50%;text-align:center}.QWdP3{display:flex;gap:24px;flex-direction:column;align-items:center}.i54RC{display:flex;align-items:center;gap:8px;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/pages/unauthorized/Unauthorized.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,cAAA,CACA,OAAA,CACA,gCAAA,CACA,QAAA,CACA,iBAAA,CAGF,OACE,YAAA,CACA,QAAA,CACA,qBAAA,CACA,kBAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,qBAAA","sourcesContent":[".errorContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  position: fixed;\n  top: 50%;\n  transform: translate(-50%, -100%);\n  left: 50%;\n  text-align: center;\n}\n\n.error {\n  display: flex;\n  gap: 24px;\n  flex-direction: column;\n  align-items: center;\n}\n\n.errorDescription {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorContainer": `cyWIT`,
	"error": `QWdP3`,
	"errorDescription": `i54RC`
};
export default ___CSS_LOADER_EXPORT___;
