import _ from 'lodash'
import React from 'react'

import Select from 'app/components/questionnaires/Select'
import IOption from 'interfaces/common/IOption'

interface IQuestionnairesProps {
  /** Questionnaires */
  questionnaires: IOption[]
  /** Handle questionnaire selection event*/
  handleQuestionnaireSelection: (value: IOption) => void
  /** Selected questionnaire value*/
  questionnaireId: string | null
  /** Parent Questionnaires */
  parentQuestionnaires: IOption[]
  /** Handle parent questionnaire selection event*/
  handleParentQuestionnaireSelection: (value: IOption) => void
  /** Selected parent questionnaire value*/
  parentQuestionnaireItemId: string | null
  /** Disable form selection if already selected*/
  disableFormSelection: boolean
}

/**
 * App action
 * @param {object} props
 * @param {IOption[]} props.questionnaires
 * @param {(value: IOption) => void} props.handleQuestionnaireSelection
 * @param {string} props.questionnaireId
 * @param {IOption[]} props.parentQuestionnaires
 * @param {(value: IOption) => void} props.handleParentQuestionnaireSelection
 * @param {string} props.parentQuestionnaireItemId
 * @param {boolean} props.disableFormSelection
 */
const Questionnaires: React.FC<IQuestionnairesProps> = ({
  questionnaires,
  handleQuestionnaireSelection,
  parentQuestionnaireItemId,
  handleParentQuestionnaireSelection,
  questionnaireId,
  parentQuestionnaires,
  disableFormSelection,
}: IQuestionnairesProps): React.ReactElement => {
  return (
    <>
      <Select
        data={questionnaires}
        onChange={handleQuestionnaireSelection}
        label="Select form"
        placeholder="Select form of project"
        value={questionnaireId}
        disabled={disableFormSelection}
      />
      {!_.isEmpty(parentQuestionnaires) && (
        <Select
          data={parentQuestionnaires}
          onChange={handleParentQuestionnaireSelection}
          label="Select parent form"
          placeholder="Select parent form"
          value={parentQuestionnaireItemId}
        />
      )}
    </>
  )
}

export default Questionnaires
