// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q3ivZ::part(button){color:var(--banner-information-icon-message-color)}.E34yK{display:flex;align-items:flex-end;flex-direction:column;margin-top:24px}.Z9P9o{display:flex;justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./src/app/components/review/reject/rejectBanner/RejectBanner.module.scss"],"names":[],"mappings":"AAAA,qBACE,kDAAA,CAGF,OACE,YAAA,CACA,oBAAA,CACA,qBAAA,CACA,eAAA,CAEF,OACE,YAAA,CACA,wBAAA","sourcesContent":[".readMore::part(button) {\n  color: var(--banner-information-icon-message-color);\n}\n\n.rejectInfo {\n  display: flex;\n  align-items: flex-end;\n  flex-direction: column;\n  margin-top: 24px;\n}\n.actions {\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readMore": `Q3ivZ`,
	"rejectInfo": `E34yK`,
	"actions": `Z9P9o`
};
export default ___CSS_LOADER_EXPORT___;
