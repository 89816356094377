export const TOAST_DURATION = 5000
export const COMMENTS_PER_PAGE = 10
export const ERR_REQ_FIELD_APP = 'Please fix the errors in the form'
export const MSG_ALREADY_SAVED_DATA = 'All changes are already saved'
export const MSG_SAVED_DATA = 'All changes have been saved'
export const PDF_FONT_FAMILY_NAME = 'Open Sans'
export const PDF_FONTS_SRC = [
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf', fontWeight: 300 },
]

export enum API_TYPES {
  QUESTIONNAIRE_API = 'QUESTIONNAIRE_API',
  PROJECT_API = 'PROJECT_API',
  APP_NAMESPACE = 'APP_NAMESPACE',
  FACADE_API = 'FACADE_API',
  MASTER_API = 'MASTER_API',
}

const developmentApi: Record<API_TYPES, string> = {
  QUESTIONNAIRE_API: 'https://questionnaire-api-ch-elektra.os-dev.io/api',
  PROJECT_API: 'https://project-api-ch-elektra.os-dev.io/api',
  APP_NAMESPACE: '',
  FACADE_API: 'https://facade-api-ch-elektra.os-dev.io/api',
  MASTER_API: 'https://master-data-api-ch-elektra.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  QUESTIONNAIRE_API: 'https://questionnaire-api-ch-preprod.os-dev.io/api',
  PROJECT_API: 'https://project-api-ch-preprod.os-dev.io/api',
  APP_NAMESPACE: 'APP_PROFILE_832b1371-30df-468d-86b5-b7cbade1bcea',
  FACADE_API: 'https://facade-api-ch-preprod.os-dev.io/api',
  MASTER_API: 'https://master-data-api-ch-preprod.os-dev.io/api',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env.NODE_ENV !== 'development'
      ? process.env[`REACT_APP_${type}`] ?? productionApi[type as API_TYPES]
      : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
