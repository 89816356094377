import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/formField/formFieldCheckbox/FormFieldCheckbox.module.scss'
import FormFieldChildren from 'app/components/form/formField/formFieldChildren'
import FormFieldLabel from 'app/components/form/formField/formFieldLabel'
import FormFieldMessage from 'app/components/form/formField/formFieldMessage'
import { WppCheckbox } from 'buildingBlocks'
import { LabelType, Orientation } from 'config/enums'
import IOption from 'interfaces/common/IOption'
import { IFieldComment } from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { IReview } from 'interfaces/review/IReview'

interface IFormFieldCheckboxProps {
  /** IField interface */
  field: IField
  /** If user is project member*/
  isProjectMember: boolean
  /** Review Object */
  review: IReview | null
  /** Is comment mode enabled*/
  enableCommentMode: boolean
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Handle field comment */
  handleComment: (field: IField, value: string) => void
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
}

/**
 * Create checkbox field
 * @param {object} props
 * @param {IField} props.field
 * @param {IReview} props.review
 * @param {boolean} props.enableCommentMode
 * @param {boolean} props.isProjectMember
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 * @param {(field: IField, value: string) => void} props.handleComment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 */
const FormFieldCheckbox: React.FC<IFormFieldCheckboxProps> = ({
  field,
  handleChange,
  handleComment,
  enableCommentMode,
  isProjectMember,
  review,
  setFormFieldComments,
}: IFormFieldCheckboxProps): React.ReactElement => {
  const { disabled, value, isRequired } = field
  const { orientation, options } = field.fieldConfig
  const Options = value.map((option: IOption) => option.id)

  const onChange = (option: IOption): void => {
    const Value = !_.isEqual(Options.indexOf(option.id), -1)
      ? value.filter((val: IOption) => !_.isEqual(val.id, option.id))
      : _.uniqBy([...value, option], 'id')

    handleChange({
      field,
      value: Value,
    })
  }

  return (
    <>
      <div
        className={classNames(
          {
            [styles.displayVertical]: _.isEqual(orientation, Orientation.VERTICAL),
            [styles.displayHorizontal]: _.isEqual(orientation, Orientation.HORIZONTAL),
          },
          styles.container,
        )}
      >
        {options.map((option: IOption, index: number) => (
          <div key={option.id}>
            <div
              style={{
                display: 'flex',
                marginTop: !_.isEqual(index, 0) && _.isEqual(orientation, Orientation.VERTICAL) ? 10 : 0,
                marginRight: _.isEqual(orientation, Orientation.HORIZONTAL) ? 10 : 0,
              }}
            >
              <WppCheckbox
                key={option.id}
                id={`checkbox-${field.id}-${index + 1}`}
                disabled={disabled}
                required={isRequired}
                checked={!_.isEqual(Options.indexOf(option.id), -1)}
                onWppChange={() => onChange(option)}
              />
              <FormFieldLabel
                type={LabelType.BODY}
                id={`${field.id}-${option.id}`}
                text={option.label}
                helpModal={option.help_model}
                disabled={field.disabled}
                handleChange={() => onChange(option)}
                required={isRequired}
                isCompletedField={false}
              />
            </div>
            <FormFieldChildren
              field={{
                ...field,
                value,
              }}
              handleComment={handleComment}
              optionValue={option.id}
              handleChange={handleChange}
              enableCommentMode={enableCommentMode}
              review={review}
              setFormFieldComments={setFormFieldComments}
              isProjectMember={isProjectMember}
            />
          </div>
        ))}
      </div>
      <FormFieldMessage field={field} />
    </>
  )
}

export default FormFieldCheckbox
