import React from 'react'

import { WppEmptyNoAccess, WppGrid, WppTypography } from 'buildingBlocks'
import styles from 'pages/unauthorized/Unauthorized.module.scss'

interface IUnauthorizedProps {
  /** Message text */
  message: string
}

/**
 * Unauthorized
 */
const Unauthorized: React.FC<IUnauthorizedProps> = ({ message }: IUnauthorizedProps): React.ReactElement => {
  return (
    <WppGrid container className={styles.errorContainer} rowSpacing={2}>
      <WppGrid item all={8} className={styles.error}>
        <WppEmptyNoAccess />
        <div className={styles.errorDescription}>
          <WppTypography type="m-strong" tag="p">
            Not Authorized
          </WppTypography>
          <WppTypography type="m-body">{message}</WppTypography>
        </div>
      </WppGrid>
    </WppGrid>
  )
}

export default Unauthorized
