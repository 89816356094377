import { useOs } from '@wppopen/react'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import styles from 'app/components/review/sendToReview/SendToReview.module.scss'
import { WppButton, WppSideModal, WppSelect, WppListItem, WppAvatar } from 'buildingBlocks'
import { IReviewer } from 'interfaces/review/IReviewer'
import { SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types'

interface ISendToReviewProps {
  /** Reviewers data */
  reviewers: IReviewer[]
  /** Handle send to review action */
  handleSendToReview: (reviewer: IReviewer | null) => void
  /** Initial Reviewer email */
  reviewerEmail: string | null
  /** Is send to review button is disabled */
  disabled: boolean
  /** Validate app*/
  validateApp: () => boolean
}

/**
 * SendToReview Modal component
 * @param {object} props
 * @param {IReviewer[]} props.reviewers
 * @param {Function} props.validateApp
 * @param {boolean} props.disabled
 * @param {(reviewer: IReviewer | null) => void} props.handleSendToReview
 * @param {string} props.reviewerEmail
 */
const SendToReview: React.FC<ISendToReviewProps> = ({
  reviewers,
  handleSendToReview,
  reviewerEmail,
  disabled,
  validateApp,
}: ISendToReviewProps): React.ReactElement => {
  const [open, setOpen] = useState(false)
  const { osContext } = useOs()
  const [reviewer, setReviewer] = useState<IReviewer | null>(null)
  const Reviewers = reviewers.filter(
    (reviewer: IReviewer) => !_.isEqual(_.toLower(reviewer.email), _.toLower(osContext.userDetails.email)),
  )
  const handleModal = () => {
    if (validateApp()) setOpen((prevState: boolean) => !prevState)
  }

  useEffect(() => {
    if (!_.isNull(reviewerEmail)) {
      const rev = Reviewers.find((re: IReviewer) => _.isEqual(re.email, reviewerEmail))
      setReviewer(rev ?? null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewerEmail])

  return (
    <>
      <WppButton
        id="btn-send-to-review"
        size="s"
        disabled={disabled}
        data-testid="btn-send-to-review"
        onClick={handleModal}
      >
        Send to review
      </WppButton>
      {createPortal(
        <WppSideModal
          data-testid="side-modal"
          size="s"
          open={open}
          onWppSideModalClose={handleModal}
          onWppSideModalOpen={handleModal}
        >
          <h3 slot="header">Select reviewer</h3>
          <div slot="body">
            <WppSelect
              data-testid="reviewer-change"
              onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
                setReviewer(event.detail.value)
              }}
              placeholder="ex. Nathalie, or james@consulting.com"
              value={reviewer}
              withSearch
              withFolder
            >
              {Reviewers.map((reviewer: IReviewer) => (
                <WppListItem value={reviewer} key={reviewer.id}>
                  <p slot="label">
                    {reviewer.firstname} {reviewer.lastname}
                  </p>
                  <p slot="caption">{reviewer.email}</p>
                  <WppAvatar
                    size="s"
                    src={reviewer.avatarUrl}
                    slot="left"
                    name={`${reviewer.firstname} ${reviewer.lastname}`}
                  />
                </WppListItem>
              ))}
            </WppSelect>
          </div>
          <div slot="actions" className={styles.actions}>
            <WppButton data-testid="btn-cancel" variant="secondary" onClick={handleModal}>
              Cancel
            </WppButton>
            <WppButton
              disabled={!reviewer}
              data-testid="btn-send-to-review-confirm"
              onClick={() => handleSendToReview(reviewer)}
            >
              Send for review
            </WppButton>
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  )
}

export default SendToReview
