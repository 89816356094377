import { FullscreenAppContext } from '@wppopen/core'
import { AxiosResponse } from 'axios'
import HTTP_STATUS_CODES from 'http-status-codes'
import _ from 'lodash'

import {
  getParentQuestionnairesAPIPath,
  getProjectAppsAPIPath,
  getQuestionnaireAPIPath,
  getQuestionnaireByIdAPIPath,
  getQuestionnairesAPIPath,
  getSelectedQuestionnaireAPIPath,
} from 'config/apiPaths'
import { ERR_REQ_FIELD_APP, MSG_ALREADY_SAVED_DATA, MSG_SAVED_DATA } from 'config/constants'
import {
  AppAction,
  QuestionnaireStatus,
  QuestionnaireStatusValue,
  StepAction,
  TagVariant,
  ToastMessageType,
} from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IOption from 'interfaces/common/IOption'
import { IParentQuestionnaire } from 'interfaces/common/IParentQuestionnaire'
import IResponseApp from 'interfaces/field/response/IResponseApp'
import { IProject } from 'interfaces/project/IProject'
import { IProjectApp } from 'interfaces/project/IProjectApp'
import IProjectQuestionnaire from 'interfaces/projectQuestionnaire/IProjectQuestionnaire'
import IQuestionnaire from 'interfaces/questionnaire/IQuestionnaire'
import { IReview } from 'interfaces/review/IReview'
import { IReviewer } from 'interfaces/review/IReviewer'
import { IReviewNotification } from 'interfaces/review/IReviewNotification'
import { IQuestionnaireState } from 'interfaces/state/IQuestionnaireState'
import AxiosService from 'lib/AxiosService'
import { setApp, setReview, setState, setLoading, setError, setReviewers } from 'pages/questionnaire/store/actions'
import FormHelper from 'utils/form/FormHelper'
import FormValidation from 'utils/form/FormValidation'
import SubmitHelper from 'utils/form/SubmitHelper'
import FieldCommentHelper from 'utils/formField/FieldCommentHelper'
import PermissionHelper from 'utils/permission/PermissionHelper'
import ProjectHelper from 'utils/project/ProjectHelper'
import ReviewHelper from 'utils/review/ReviewHelper'
import SharedHelper from 'utils/SharedHelper'

export default class QuestionnaireHelper {
  /**
   * Enable/Disable comment mode
   * @param {IQuestionnaireState} state
   * @param {Function} dispatch
   * @returns {void}
   */
  public static readonly handleCommentMode = (state: IQuestionnaireState, dispatch: Function): void => {
    dispatch(setState({ ...state, enableCommentMode: !state.enableCommentMode }))
  }
  /**
   * Get Questionnaires
   * @param {string} accessToken
   * @param {FullscreenAppContext} osContext
   * @returns {Promise<IOption[]>}
   */
  public static readonly getQuestionnaires = async (
    accessToken: string,
    osContext: FullscreenAppContext,
  ): Promise<IOption[]> => {
    const { TENANT_ID } = SharedHelper.getAppConfigurationData(osContext)
    const axiosService = new AxiosService(accessToken)
    const questionnaires: AxiosResponse<IQuestionnaire[]> = await axiosService.get(
      getQuestionnairesAPIPath(TENANT_ID),
      TENANT_ID,
    )
    return _.sortBy(
      SharedHelper.getOptions(questionnaires.data, 'id', 'name', 'parentQuestionnaireId'),
      ['label'],
      ['asc'],
    )
  }

  /**
   * Get Questionnaire by id
   * @param {string} accessToken
   * @param {FullscreenAppContext} osContext
   * @param {string} id
   * @returns {Promise<IQuestionnaire>}
   */
  public static readonly getQuestionnaireById = async (
    accessToken: string,
    osContext: FullscreenAppContext,
    id: string,
  ): Promise<IQuestionnaire> => {
    const { TENANT_ID } = SharedHelper.getAppConfigurationData(osContext)
    const axiosService = new AxiosService(accessToken)
    const questionnaire: AxiosResponse<IQuestionnaire> = await axiosService.get(
      getQuestionnaireByIdAPIPath(id),
      TENANT_ID,
    )
    return questionnaire.data
  }

  /**
   * Get Selected Questionnaire
   * @param {string} accessToken
   * @param {FullscreenAppContext} osContext
   * @returns {Promise<IOption[]>}
   */
  public static readonly getSelectedQuestionnaire = async (
    accessToken: string,
    osContext: FullscreenAppContext,
  ): Promise<IProjectQuestionnaire> => {
    const { TENANT_ID, ITEM_ID, PROJECT_ID } = SharedHelper.getAppConfigurationData(osContext)
    const axiosService = new AxiosService(accessToken)
    const questionnaire: AxiosResponse<IProjectQuestionnaire> = await axiosService.get(
      getSelectedQuestionnaireAPIPath(TENANT_ID, PROJECT_ID, ITEM_ID),
      TENANT_ID,
    )
    return questionnaire.data
  }

  /**
   * Update category data by category id
   * @param {ICategory[]} categories
   * @param {ICategory} updatedCategory
   * @param {string} categoryId
   * @returns {ICategory[]}
   */
  public static readonly updateCategoryById = (
    categories: ICategory[],
    updatedCategory: ICategory,
    categoryId: string,
  ): ICategory[] => {
    return categories.map((categoryParam: ICategory) => {
      if (_.isEqual(categoryParam.id, categoryId)) {
        return updatedCategory
      }
      return categoryParam
    })
  }

  /**
   * Get Parent Questionnaire options
   * @param {IProjectApp[]} projectApps
   * @param {IParentQuestionnaire[]} parentQuestionnaires
   * @param {boolean} isProjectMember
   * @returns {IOption[]}
   */
  public static readonly getParentQuestionnaireOptions = (
    projectApps: IProjectApp[],
    parentQuestionnaires: IParentQuestionnaire[],
  ): IOption[] => {
    const reviewHelper = new ReviewHelper()
    let options: IOption[] = []

    for (let projectApp of projectApps) {
      for (let parentQuestionnaire of parentQuestionnaires) {
        if (_.isEqual(projectApp.itemId, parentQuestionnaire.itemId)) {
          const reviewData = reviewHelper.getReviewData(parentQuestionnaire.review)
          options.push({
            id: parentQuestionnaire.itemId,
            label: projectApp.location.application.name,
            subLabel: projectApp.location.phase.name,
            tag: {
              label: reviewData ? reviewData.statusStr : QuestionnaireStatusValue.DRAFT,
              variant: reviewData ? reviewData.statusVariant : TagVariant.NEUTRAL,
            },
          })
        }
      }
    }
    return options
  }

  /**
   * Get Parent Questionnaires
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} questionnaireId
   * @param {string} projectId
   * @returns {Promise<IOption[]>}
   */
  public static readonly getParentQuestionnaires = async (
    accessToken: string,
    tenantId: string,
    parentQuestionnaireId: string,
    projectId: string,
  ): Promise<IOption[]> => {
    const axiosService = new AxiosService(accessToken)
    const parentQuestionnaires: AxiosResponse<IParentQuestionnaire[]> = await axiosService.get(
      getParentQuestionnairesAPIPath(parentQuestionnaireId, projectId),
      tenantId,
    )

    let options: IOption[] = []
    if (!_.isEmpty(parentQuestionnaires.data)) {
      const projectApps: AxiosResponse<IProjectApp[]> = await axiosService.post(
        getProjectAppsAPIPath(projectId),
        {},
        tenantId,
      )

      options = QuestionnaireHelper.getParentQuestionnaireOptions(projectApps.data, parentQuestionnaires.data)
    }
    return options
  }

  /**
   * Handle reviewer action
   */
  public static readonly handleReviewerAction = async ({
    accessToken,
    tenantId,
    appInstanceId,
    projectQuestionnaireId,
    questionnaire,
    statusValue,
    statusReason,
    dispatch,
    showAlert,
    showError,
    osContext,
  }: {
    accessToken: string
    tenantId: string
    appInstanceId: string
    projectQuestionnaireId: string
    questionnaire: IQuestionnaireState
    statusValue: string
    statusReason: string
    dispatch: Function
    showAlert: Function
    showError: Function
    osContext: FullscreenAppContext
  }) => {
    const { app } = questionnaire
    try {
      if (!app) return
      dispatch(setLoading(true))
      const reviewHelper = new ReviewHelper()
      const reviewData: {
        data: IReview
        notificationSubmitted: boolean
      } | null = await reviewHelper.updateReviewStatus(
        accessToken,
        projectQuestionnaireId,
        statusValue,
        statusReason,
        tenantId,
        appInstanceId,
      )

      if (reviewData) {
        dispatch(setReview(reviewHelper.getReviewData(reviewData.data, app.isProjectMember)))

        if (_.isEqual(statusValue, QuestionnaireStatus.DRAFT)) {
          const formHelper = new FormHelper()
          const updatedApp = formHelper.updateEditableAppStatus(
            app,
            PermissionHelper.hasAppEditor(osContext.permissions),
          )
          dispatch(setApp(updatedApp))
        }

        let statusMessage = `The request has been ${
          _.isEqual(statusValue, QuestionnaireStatus.APPROVED) ? 'approved' : 'rejected'
        }`

        if (reviewData.notificationSubmitted) {
          showAlert(statusMessage, ToastMessageType.SUCCESS)
        } else {
          showAlert(`${statusMessage} but notification has not sent due to an error.`, ToastMessageType.WARNING)
        }

        dispatch(setLoading(false))
      }
    } catch {
      dispatch(setLoading(false))
      showError()
    }
  }

  /**
   * Handle cancel review
   */
  public static readonly handleCancelReview = async ({
    accessToken,
    tenantId,
    appInstanceId,
    projectQuestionnaireId,
    questionnaire,
    osContext,
    dispatch,
    showAlert,
  }: {
    accessToken: string
    appInstanceId: string
    tenantId: string
    projectQuestionnaireId: string
    questionnaire: IQuestionnaireState
    osContext: FullscreenAppContext
    dispatch: Function
    showAlert: Function
  }) => {
    const { app, review } = questionnaire

    if (!app || !review) return
    try {
      dispatch(setLoading(true))
      const reviewHelper = new ReviewHelper()
      const notificationSubmitted: boolean = await reviewHelper.deleteReview(
        accessToken,
        projectQuestionnaireId,
        tenantId,
        appInstanceId,
      )
      const formHelper = new FormHelper()

      if (notificationSubmitted) {
        showAlert('Review has been successfully canceled.', ToastMessageType.SUCCESS)
      } else {
        showAlert(
          'Review has been successfully canceled but notification has not sent due to an error.',
          ToastMessageType.WARNING,
        )
      }

      const updatedApp = formHelper.updateEditableAppStatus(app, PermissionHelper.hasAppEditor(osContext.permissions))
      dispatch(
        setState({
          ...questionnaire,
          app: updatedApp,
          loading: false,
          review: null,
        }),
      )
    } catch {
      dispatch(setLoading(false))
    }
  }

  /**
   * Validate app
   * @param {IQuestionnaireState} questionnaire
   * @param {Function} showAlert
   * @param {Function} dispatch
   * @returns {void}
   */
  public static readonly validateApp = (
    questionnaire: IQuestionnaireState,
    showAlert: Function,
    dispatch: Function,
  ): boolean => {
    const { app } = questionnaire

    if (!app) return false

    const formValidation = new FormValidation()
    const updatedApp = formValidation.validateAppByCategoryId(app)

    if (!updatedApp.isValid) {
      showAlert(ERR_REQ_FIELD_APP, ToastMessageType.ERROR)
      const updatedState: IQuestionnaireState = { ...questionnaire, app: updatedApp }
      dispatch(setState(updatedState))
      return false
    }
    return true
  }

  /**
   * Handle send to review
   */
  public static readonly handleSendToReview = async ({
    accessToken,
    osContext,
    projectQuestionnaireId,
    questionnaire,
    reviewer,
    category,
    dispatch,
    showAlert,
    showError,
  }: {
    accessToken: string
    osContext: FullscreenAppContext
    projectQuestionnaireId: string
    questionnaire: IQuestionnaireState
    reviewer: IReviewer | null
    category: ICategory
    dispatch: Function
    showAlert: Function
    showError: Function
  }): Promise<void> => {
    const { app, review } = questionnaire
    const { TENANT_ID, APP_INSTANCE_ID } = SharedHelper.getAppConfigurationData(osContext)
    if (!app) return

    const formValidation = new FormValidation()
    const updatedApp = formValidation.validateAppByCategoryId(app)

    if (!updatedApp.isValid) {
      showAlert(ERR_REQ_FIELD_APP, ToastMessageType.ERROR)
      const updatedState: IQuestionnaireState = { ...questionnaire, app: updatedApp }
      dispatch(setState(updatedState))
      return
    }

    try {
      dispatch(setLoading(true))
      await this.onSaveProgress(accessToken, osContext, questionnaire, category, dispatch)
      const reviewHelper = new ReviewHelper()
      const reviewData: IReviewNotification | null = await reviewHelper.sendToReview(
        accessToken,
        reviewer?.email ?? '',
        projectQuestionnaireId,
        TENANT_ID,
        APP_INSTANCE_ID,
        !_.isNull(review),
      )
      if (reviewData) {
        if (reviewData.notificationSubmitted) {
          showAlert('Your request has been sent for review', ToastMessageType.SUCCESS)
        } else {
          showAlert(
            'Your request has been saved but notification has not sent due to an error.',
            ToastMessageType.WARNING,
          )
        }
        const formHelper = new FormHelper()
        const updatedApp = formHelper.updateEditableAppStatus(app, false)

        dispatch(
          setState({
            ...questionnaire,
            app: updatedApp,
            review: reviewHelper.getReviewData(reviewData.data),
          }),
        )
      }
      dispatch(setLoading(false))
    } catch {
      dispatch(setLoading(false))
      showError()
    }
  }

  private static readonly saveStep = async ({
    accessToken,
    osContext,
    questionnaire,
    app,
    projectQuestionnaireId,
    dispatch,
    showAlert,
    currentCategory,
    nextCategory,
    stepAction,
  }: {
    accessToken: string
    osContext: FullscreenAppContext
    questionnaire: IQuestionnaireState
    app: IApp
    projectQuestionnaireId: string
    nextCategory: ICategory
    dispatch: Function
    showAlert: Function
    currentCategory: ICategory
    stepAction: StepAction
  }) => {
    let appStatus = questionnaire.appStatus
    let updatedApp = app
    const formHelper = new FormHelper()
    let prevCategory: ICategory = currentCategory

    if (_.isEqual(stepAction, StepAction.NEXT) || _.isEqual(stepAction, StepAction.STEP)) {
      dispatch(setLoading(true))
      prevCategory = await this.onSaveProgress(accessToken, osContext, questionnaire, prevCategory, dispatch)

      updatedApp = formHelper.updateCategoryStatus(updatedApp, prevCategory.id, false)
      updatedApp = await FieldCommentHelper.updateCommentForCategory(
        accessToken,
        osContext.tenant.id,
        projectQuestionnaireId,
        updatedApp,
        nextCategory.id,
        showAlert,
      )
      appStatus = {
        ...questionnaire.appStatus,
        isAppTouched: false,
      }
    } else {
      updatedApp = formHelper.updateCategoryStatus(updatedApp, prevCategory.id, false)
    }

    dispatch(
      setState({
        ...questionnaire,
        appStatus,
        loading: false,
        app: {
          ...updatedApp,
          currentCategory: nextCategory,
        },
        activeStep: nextCategory.categoryIndex,
      }),
    )
  }

  /**
   * Handle Stepper change
   */
  public static readonly handleStep = async ({
    accessToken,
    osContext,
    questionnaire,
    projectQuestionnaireId,
    stepNumber,
    dispatch,
    showAlert,
    stepAction,
    nextCategory,
  }: {
    accessToken: string
    osContext: FullscreenAppContext
    questionnaire: IQuestionnaireState
    projectQuestionnaireId: string
    stepNumber: number
    stepAction: StepAction
    dispatch: Function
    showAlert: Function
    nextCategory: ICategory
  }) => {
    const { app } = questionnaire
    try {
      if (!app) {
        return
      }

      if (_.gt(stepNumber, app.categories.length)) {
        return
      }

      if (_.isEqual(stepNumber, 0) && _.isEqual(stepAction, StepAction.PREVIOUS)) {
        return
      }

      if (_.isEqual(stepAction, StepAction.STEP) && _.isEqual(nextCategory.id, app.currentCategory.id)) {
        return
      }

      const formValidation = new FormValidation()
      let updatedApp = formValidation.validateAppByCategoryId(app, app.currentCategory.id)
      let currentCategory: ICategory = updatedApp.currentCategory

      if (!currentCategory.isValid && app.isAppEditor) {
        showAlert(ERR_REQ_FIELD_APP, ToastMessageType.ERROR)
        dispatch(
          setState({
            ...questionnaire,
            app: updatedApp,
          }),
        )
        return
      }

      if (_.isEqual(stepAction, StepAction.STEP) && app.isAppEditor) {
        updatedApp = formValidation.validatePreviousCategories(app, nextCategory)
      }

      await this.saveStep({
        accessToken,
        app: _.cloneDeep(updatedApp),
        dispatch,
        osContext,
        projectQuestionnaireId,
        questionnaire,
        currentCategory,
        nextCategory,
        showAlert,
        stepAction,
      })
    } catch {
      dispatch(setLoading(false))
      showAlert(
        'Your data has not been saved. Please try again later or contact customer support if the problem persists.',
        ToastMessageType.ERROR,
        'Oops! Something went wrong',
      )
    }
  }

  /**
   * Save form data
   * @param {string} osContext
   * @param {FullscreenAppContext} osContext
   * @param {IQuestionnaireState} questionnaire
   * @param {ICategory} category
   * @param {Function} dispatch
   */
  public static readonly onSaveProgress = async (
    accessToken: string,
    osContext: FullscreenAppContext,
    { app }: IQuestionnaireState,
    category: ICategory,
    dispatch: Function,
  ): Promise<ICategory> => {
    if (!app?.isAppEditor) {
      return category
    }

    const { ITEM_ID, PROJECT_ID, TENANT_ID, HOME_URL } = SharedHelper.getAppConfigurationData(osContext)
    if (!app || !category.dirty) {
      return category
    }
    const submitHelper = new SubmitHelper()

    const formHelper = new FormHelper()
    let updatedCategory = await formHelper.preSaveCheck(category, accessToken, TENANT_ID, HOME_URL, PROJECT_ID)

    dispatch(
      setApp({
        ...app,
        categories: this.updateCategoryById(app.categories, updatedCategory, updatedCategory.id),
      }),
    )

    const FormData = submitHelper.onSaveProgress(updatedCategory)
    await submitHelper.saveToDb(accessToken, ITEM_ID, PROJECT_ID, TENANT_ID, FormData)

    return updatedCategory
  }

  /**
   * Handle app actions i.e Submit, Save Progress, Cancel
   * @param {string} appAction
   */
  public static readonly handleAppAction = async (
    {
      accessToken,
      appAction,
      dispatch,
      displayMessage,
      osContext,
      questionnaire,
      showAlert,
      showError,
    }: {
      accessToken: string
      osContext: FullscreenAppContext
      questionnaire: IQuestionnaireState
      appAction: string
      dispatch: Function
      showAlert: Function
      showError: Function
      displayMessage: boolean
    },
    callback: (message: ToastMessageType) => void,
  ): Promise<IQuestionnaireState | null> => {
    const { app, activeStep, appStatus } = questionnaire
    if (!app) {
      return null
    }

    if (!app.isAppEditor) {
      callback(ToastMessageType.SUCCESS)
      return questionnaire
    }

    const formValidation = new FormValidation()

    const updatedApp = _.isEqual(appAction, AppAction.PDF_VIEWER)
      ? formValidation.validateAppByCategoryId(app)
      : formValidation.validateAppByCategoryId(app, app.categories[activeStep - 1].id)

    if (!updatedApp.isValid) {
      showAlert(ERR_REQ_FIELD_APP, ToastMessageType.ERROR)
      const updatedState: IQuestionnaireState = { ...questionnaire, app: updatedApp }
      dispatch(setState(updatedState))
      callback(ToastMessageType.ERROR)
      return updatedState
    }

    let updatedCategory: ICategory = app.categories[activeStep - 1]
    const alertMessage = updatedCategory.dirty ? MSG_SAVED_DATA : MSG_ALREADY_SAVED_DATA
    const alertType = updatedCategory.dirty ? ToastMessageType.SUCCESS : ToastMessageType.INFORMATION

    dispatch(setLoading(true))

    const appState = _.isEqual(appAction, AppAction.CANCEL) ? appStatus.isAppTouched : false
    try {
      switch (appAction) {
        case AppAction.SAVE_AND_EXIT:
        case AppAction.PDF_VIEWER:
        case AppAction.SAVE_PROGRESS: {
          updatedCategory = await this.onSaveProgress(accessToken, osContext, questionnaire, updatedCategory, dispatch)
          if (displayMessage) showAlert(alertMessage, alertType)
          break
        }
        default:
          break
      }
      const questionnaireState: IQuestionnaireState = {
        ...questionnaire,
        appStatus: {
          ...questionnaire.appStatus,
          isAppTouched: appState,
          openModal: appState,
        },
        app: {
          ...app,
          categories: this.updateCategoryById(app.categories, updatedCategory, updatedCategory.id),
        },
        loading: false,
      }
      dispatch(setState(questionnaireState))
      callback(ToastMessageType.SUCCESS)
      return questionnaireState
    } catch {
      showError()
      callback(ToastMessageType.ERROR)
      dispatch(setLoading(false))
      return {
        ...questionnaire,
        error: true,
      }
    }
  }

  /**
   * Load initial data
   */
  public static readonly initLoad = async (
    accessToken: string,
    osContext: FullscreenAppContext,
    questionnaire: IQuestionnaireState,
    projectQuestionnaireId: string,
    questionnaireId: string,
    dispatch: Function,
    showAlert: Function,
  ) => {
    const { PROJECT_ID, TENANT_ID, ITEM_ID, USER_EMAIL } = SharedHelper.getAppConfigurationData(osContext)
    try {
      if (!PROJECT_ID) {
        return
      }
      dispatch(
        setState({
          ...questionnaire,
          activeStep: 1,
          app: null,
        }),
      )

      const formHelper = new FormHelper()
      const axiosService = new AxiosService(accessToken)

      const response: AxiosResponse<IResponseApp> = await axiosService.get(
        getQuestionnaireAPIPath(questionnaireId, PROJECT_ID, TENANT_ID, ITEM_ID),
        TENANT_ID,
      )

      const projectInfo: IProject | null = await ProjectHelper.getProjectInfo(
        accessToken,
        PROJECT_ID,
        TENANT_ID,
        USER_EMAIL,
      )

      if (!_.isNull(projectInfo)) {
        dispatch(setReviewers(projectInfo.members))
      }

      let isAppEditable = true
      const reviewHelper = new ReviewHelper()
      try {
        const review: IReview | null = await reviewHelper.getReview(
          accessToken,
          USER_EMAIL,
          projectQuestionnaireId,
          TENANT_ID,
        )
        isAppEditable = _.isNull(review) ? true : _.isEqual(review?.statusValue, QuestionnaireStatus.DRAFT)
        dispatch(setReview(review))
      } catch (e: any) {
        if (!_.isEqual(e.response.status, HTTP_STATUS_CODES.NOT_FOUND)) {
          dispatch(setError(true))
        }
      }

      const UpdatedApp: IApp = await formHelper.getApp({
        accessToken,
        isAppEditable,
        isProjectMember: !_.isNull(projectInfo),
        permissions: osContext.permissions,
        projectId: PROJECT_ID,
        projectQuestionnaireId,
        showAlert,
        tenantId: TENANT_ID,
        app: response.data,
      })

      dispatch(setApp(UpdatedApp))
    } catch {
      dispatch(setError(true))
    }
  }
}
