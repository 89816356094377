import { useEffect, useState } from 'react'

import { IToast } from 'interfaces/common/IToast'

const useToast = () => {
  const [toastRef, setToastRef] = useState<HTMLWppToastContainerElement | null>()

  const showToast = (toast: IToast) => {
    toastRef?.addToast(toast)
  }

  useEffect(() => {
    const Element: HTMLWppToastContainerElement | null = document.querySelector('#wpp-toast')
    setToastRef(Element)
  }, [toastRef])

  return {
    showToast,
  }
}

export default useToast
