import React from 'react'

import { WppEmptyError, WppGrid, WppTypography } from 'buildingBlocks'
import styles from 'pages/appError/ApplicationError.module.scss'

/**
 * ApplicationError
 */
const ApplicationError: React.FC = (): React.ReactElement => {
  return (
    <WppGrid container className={styles.errorContainer} rowSpacing={2}>
      <WppGrid item all={8} className={styles.error}>
        <WppEmptyError />
        <div className={styles.errorDescription}>
          <WppTypography type="m-strong" tag="p">
            Unable to Load Project Brief
          </WppTypography>
          <WppTypography type="m-body">
            Project Brief is unable to load due to an unknown error. Please open a support ticket for further
            investigation.
          </WppTypography>
        </div>
      </WppGrid>
    </WppGrid>
  )
}

export default ApplicationError
