export const SET_QUESTIONNAIRES = 'SET_QUESTIONNAIRES'
export const SET_QUESTIONNAIRE_ID = 'SET_QUESTIONNAIRE_ID'
export const SET_PARENT_QUESTIONNAIRE_ITEM_ID = 'SET_PARENT_QUESTIONNAIRE_ITEM_ID'
export const SET_PARENT_QUESTIONNAIRES = 'SET_PARENT_QUESTIONNAIRES'
export const SET_INIT_LOADING = 'SET_INIT_LOADING'
export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'
export const SET_OPEN_MODAL = 'SET_OPEN_MODAL'
export const SET_STATE = 'SET_STATE'
export const SET_APP_UNAUTHORIZED = 'SET_APP_UNAUTHORIZED'
export const SET_QUESTIONNAIRE_UNAUTHORIZED = 'SET_QUESTIONNAIRE_UNAUTHORIZED'
