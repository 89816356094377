import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useCallback, useReducer } from 'react'

import Action from 'app/components/action'
import Category from 'app/components/category'
import Confirmation from 'app/components/confirmation'
import Form from 'app/components/form'
import Header from 'app/components/header'
import RejectBanner from 'app/components/review/reject/rejectBanner'
import Spinner from 'app/components/spinner/Spinner'
import Stepper from 'app/components/stepper'
import Toast from 'app/components/toast/Toast'
import { WppGrid, WppStepper } from 'buildingBlocks'
import { TOAST_DURATION } from 'config/constants'
import { AppAction, QuestionnaireStatus, StepAction, ToastMessageType } from 'config/enums'
import useMount from 'hooks/useMount'
import useToast from 'hooks/useToast'
import ICategory from 'interfaces/category/ICategory'
import { IFieldComment } from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { IReviewer } from 'interfaces/review/IReviewer'
import ApplicationError from 'pages/appError/ApplicationError'
import { questionnaireReducer, setApp, setAppStatus, setState } from 'pages/questionnaire//store/actions'
import { defaultQuestionnaireState } from 'pages/questionnaire//store/state'
import styles from 'pages/questionnaire/Questionnaire.module.scss'
import FormHelper from 'utils/form/FormHelper'
import QuestionnaireHelper from 'utils/form/QuestionnaireHelper'
import FieldCommentHelper from 'utils/formField/FieldCommentHelper'
import FormFieldHelper from 'utils/formField/FormFieldHelper'
import SharedHelper from 'utils/SharedHelper'

interface IQuestionnaireProps {
  questionnaireId: string
  projectQuestionnaireId: string
}

/**
 * Questionnaire app
 */
const Questionnaire: React.FC<IQuestionnaireProps> = ({
  questionnaireId,
  projectQuestionnaireId,
}: IQuestionnaireProps): React.ReactElement => {
  const { osContext, osApi } = useOs()
  const ACCESS_TOKEN = osApi.getAccessToken()
  const [questionnaire, dispatch] = useReducer(questionnaireReducer, defaultQuestionnaireState)
  const { app, appStatus, activeStep, review, error, loading, reviewers, enableCommentMode } = questionnaire
  const { PROJECT_ID, TENANT_ID, APP_INSTANCE_ID } = SharedHelper.getAppConfigurationData(osContext)
  const { showToast } = useToast()

  /**
   * Handle Stepper change
   * @param {number} stepNumber
   */
  const handleStep = (stepNumber: number, stepAction: StepAction, nextCategory: ICategory) => {
    QuestionnaireHelper.handleStep({
      accessToken: ACCESS_TOKEN,
      dispatch,
      osContext,
      projectQuestionnaireId,
      questionnaire,
      showAlert,
      stepAction,
      stepNumber,
      nextCategory,
    })
  }

  const showAlert = (message: string, type: ToastMessageType = ToastMessageType.INFORMATION, header: string = '') => {
    showToast({
      header,
      message,
      type,
      duration: TOAST_DURATION,
    })
  }

  const showError = () => {
    showAlert(
      'Your data has not been saved. Please try again later or contact customer support if the problem persists.',
      ToastMessageType.ERROR,
    )
  }

  /**
   * Handle app actions i.e Submit, Save Progress, Cancel
   * @param {string} appAction
   */
  const handleAppAction = async (
    appAction: string,
    callback: (message: ToastMessageType) => void,
    displayMessage: boolean = true,
  ): Promise<void> => {
    const updatedQuestionnaire = await QuestionnaireHelper.handleAppAction(
      {
        accessToken: ACCESS_TOKEN,
        appAction,
        dispatch,
        displayMessage,
        osContext,
        questionnaire,
        showAlert,
        showError,
      },
      callback,
    )

    if (
      updatedQuestionnaire &&
      (_.isEqual(appAction, AppAction.SAVE_AND_EXIT) || _.isEqual(appAction, AppAction.CANCEL)) &&
      !updatedQuestionnaire.appStatus.isAppTouched &&
      updatedQuestionnaire.app?.isValid
    ) {
      const timer = setTimeout(
        () => {
          window.history.pushState(null, '', `${osContext.tenant.homeUrl}orchestration/project/${PROJECT_ID}/workflow`)

          clearTimeout(timer)
        },
        _.isEqual(appAction, AppAction.CANCEL) ? 0 : 1500,
      )
    }
  }

  const initLoad = useCallback(async () => {
    QuestionnaireHelper.initLoad(
      ACCESS_TOKEN,
      osContext,
      questionnaire,
      projectQuestionnaireId,
      questionnaireId,
      dispatch,
      showAlert,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleComment = (field: IField, category: ICategory, value: string) => {
    FieldCommentHelper.addComment({
      accessToken: ACCESS_TOKEN,
      category,
      field,
      projectQuestionnaireId,
      osContext,
      questionnaire,
      dispatch,
      tenantId: TENANT_ID,
      value,
      showAlert,
    })
  }

  /**
   * Handle form field change
   * @param {IFieldChangeEvent} event
   */
  const handleChange = (event: IFieldChangeEvent): void => {
    if (!app) return

    const formFieldHelper = new FormFieldHelper()

    if (!formFieldHelper.isValidChange(event)) return

    const formHelper = new FormHelper()
    const UpdatedApp = formHelper.updateApp(app, event)

    dispatch(
      setState({
        ...questionnaire,
        appStatus: {
          ...questionnaire.appStatus,
          isAppTouched: true,
        },
        app: UpdatedApp,
      }),
    )
  }

  const handleSendToReview = (reviewer: IReviewer | null, category: ICategory) => {
    QuestionnaireHelper.handleSendToReview({
      accessToken: ACCESS_TOKEN,
      category,
      dispatch,
      osContext,
      projectQuestionnaireId,
      questionnaire,
      reviewer,
      showAlert,
      showError,
    })
  }

  const handleCancelReview = (projectQuestionnaireId: string) => {
    QuestionnaireHelper.handleCancelReview({
      accessToken: ACCESS_TOKEN,
      dispatch,
      projectQuestionnaireId,
      questionnaire,
      tenantId: TENANT_ID,
      appInstanceId: APP_INSTANCE_ID,
      showAlert,
      osContext,
    })
  }

  const handleReviewerAction = (statusValue: string, statusReason: string) => {
    QuestionnaireHelper.handleReviewerAction({
      accessToken: ACCESS_TOKEN,
      dispatch,
      projectQuestionnaireId,
      questionnaire,
      showAlert,
      showError,
      statusReason,
      statusValue,
      tenantId: TENANT_ID,
      appInstanceId: APP_INSTANCE_ID,
      osContext,
    })
  }

  const handleCommentMode = () => {
    QuestionnaireHelper.handleCommentMode(questionnaire, dispatch)
  }

  const setFormFieldComments = (field: IField, fieldComment: IFieldComment) => {
    FieldCommentHelper.setCommentsForFormField(app, field, fieldComment, dispatch, setApp)
  }

  const validateApp = (): boolean => {
    return QuestionnaireHelper.validateApp(questionnaire, showAlert, dispatch)
  }

  useMount(() => {
    initLoad()
  })

  if (error) {
    return <ApplicationError />
  }

  return (
    <>
      {!_.isNull(app) ? (
        <>
          {review && _.isEqual(review.statusValue, QuestionnaireStatus.DRAFT) && !_.isEmpty(review.statusReason) && (
            <RejectBanner review={review} appName={app.appName} />
          )}
          <Header
            appActionComponent={
              !_.isEmpty(app.categories) && (
                <div className={styles.actions}>
                  <Action
                    app={app}
                    activeStep={activeStep}
                    reviewers={reviewers}
                    review={review}
                    enableCommentMode={enableCommentMode}
                    handleAppAction={handleAppAction}
                    handleReviewerAction={handleReviewerAction}
                    handleCommentMode={handleCommentMode}
                    handleSendToReview={handleSendToReview}
                    handleCancelReview={handleCancelReview}
                    validateApp={validateApp}
                  />
                </div>
              )
            }
            app={app}
            review={review}
          />
          <WppGrid container className={styles.layout} rowSpacing={2}>
            {!_.isEmpty(app.categories) && !_.isEqual(app.categories.length, 1) && (
              <WppGrid className={styles.layoutCategories} item all={4}>
                <WppStepper activeStep={activeStep}>
                  {app.categories.map((category: ICategory) => (
                    <Category key={category.id} category={category} handleStep={handleStep} />
                  ))}
                </WppStepper>
              </WppGrid>
            )}
            {!_.isEmpty(app.categories) && (
              <WppGrid item all={!_.isEqual(app.categories.length, 1) ? 20 : 24}>
                <div className={styles.formContainer}>
                  <Form
                    completionRate={app.completionRate}
                    category={app.categories[activeStep - 1]}
                    app={app}
                    review={review}
                    enableCommentMode={enableCommentMode}
                    handleChange={handleChange}
                    handleComment={handleComment}
                    setFormFieldComments={setFormFieldComments}
                  />
                  <Stepper
                    app={app}
                    activeStep={activeStep}
                    isAppEditor={app.isAppEditor}
                    handleAppAction={handleAppAction}
                    handleStep={handleStep}
                  />
                </div>
              </WppGrid>
            )}
          </WppGrid>
          <Confirmation
            title={`Leave ${app.appName} form`}
            body={`Are you sure you want to leave your ${app.appName} form? Your progress will be lost.`}
            btnPrimaryText="Leave"
            btnSecondaryText="Cancel"
            handlePrimaryAction={() =>
              window.history.pushState(
                null,
                '',
                `${osContext.tenant.homeUrl}orchestration/project/${PROJECT_ID}/workflow`,
              )
            }
            handleSecondaryAction={() => dispatch(setAppStatus({ ...appStatus, openModal: false }))}
            isOpen={appStatus.openModal}
          />
        </>
      ) : (
        <Spinner />
      )}
      {loading && <Spinner />}
      <Toast />
    </>
  )
}

export default Questionnaire
