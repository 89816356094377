import React, { useState } from 'react'

import styles from 'app/components/review/reject/rejectBanner/RejectBanner.module.scss'
import { WppActionButton, WppBanner, WppButton, WppSideModal, WppTypography } from 'buildingBlocks'
import { IReview } from 'interfaces/review/IReview'

interface IRejectBannerProps {
  /** review data */
  review: IReview
  /** Application name */
  appName: string
}

/**
 * RejectBanner
 * @param {object} props
 * @param {string} props.review
 */
const RejectBanner: React.FC<IRejectBannerProps> = ({ review, appName }: IRejectBannerProps): React.ReactElement => {
  const [open, setOpen] = useState(false)
  const handleModal = () => setOpen(prevState => !prevState)

  return (
    <>
      {review.statusReason && (
        <WppBanner id="reject-banner" type="information" show>
          Your {appName} app has been rejected.
          <WppActionButton
            data-testid="btn-reject-read-more"
            id="btn-reject-read-more"
            slot="actions"
            className={styles.readMore}
            onClick={handleModal}
          >
            Read more
          </WppActionButton>
        </WppBanner>
      )}
      <WppSideModal
        id="btn-side-modal"
        disableOutsideClick
        open={open}
        size="m"
        data-testid="btn-side-modal"
        onWppSideModalClose={handleModal}
        onWppSideModalOpen={handleModal}
      >
        <h3 slot="header">Reason for rejection</h3>
        <div slot="body">
          <div>{review.statusReason}</div>
          <div className={styles.rejectInfo}>
            <WppTypography type="s-body">{review.completedAt}</WppTypography>
            <WppTypography type="s-body">Rejected by</WppTypography>
            <WppTypography type="s-strong">
              {review.reviewerFirstname} {review.reviewerLastname}
            </WppTypography>
            <WppTypography type="s-strong">{review.reviewerEmail}</WppTypography>
          </div>
        </div>
        <div slot="actions" className={styles.actions}>
          <WppButton id="btn-close" data-testid="btn-close" variant="primary" onClick={handleModal}>
            Close
          </WppButton>
        </div>
      </WppSideModal>
    </>
  )
}

export default RejectBanner
