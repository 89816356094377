import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/formField/formFieldLabel/FormFieldLabel.module.scss'
import FormFieldTooltip from 'app/components/form/formField/formFieldTooltip'
import { WppTypography, WppIconAvailableCheckmark } from 'buildingBlocks'
import { LabelType } from 'config/enums'
import IHelpModal from 'interfaces/field/IHelpModal'

interface IFormFieldLabelProps {
  /** Field ID */
  id: string
  /** Label title */
  text: string
  /** Help Modal */
  helpModal?: IHelpModal
  /** If element is disabled */
  disabled: boolean
  /** If field is required */
  required: boolean
  /** If field is complete */
  isCompletedField: boolean
  /** handle input change event */
  handleChange?: () => void
  /** Tooltip */
  tooltip?: string
  /** Field description */
  description?: string
  /** label type - heading or body */
  type?: LabelType
}

/**
 * Create label for the field
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.text
 * @param {IHelpModal} props.helpModal
 * @param {boolean} props.disabled
 * @param {boolean} props.required
 * @param {function} props.handleChange
 * @param {string} props.tooltip
 * @param {string} props.description
 * @param {LabelType} props.type
 * @param {boolean} props.isCompletedField
 */
const FormFieldLabel: React.FC<IFormFieldLabelProps> = (props: IFormFieldLabelProps): React.ReactElement => {
  const { id, text, disabled, helpModal, tooltip, description, type, handleChange, required, isCompletedField } = props

  const desc = description ?? ''
  const labelType = type ?? LabelType.HEADING
  const tooltipText = tooltip ?? ''
  const onChange = handleChange ?? null

  return (
    <>
      {!_.isEmpty(text) && (
        <div
          className={classNames(styles.container, {
            [styles.bodyContainer]: _.isEqual(labelType, 'body'),
          })}
        >
          <WppTypography
            className={classNames({
              [styles.bodyLabel]: _.isEqual(labelType, 'body'),
              [styles.label]: !_.isEqual(labelType, 'body'),
            })}
            aria-disabled={disabled}
            id={`label-${id}`}
            type={_.isEqual(labelType, 'heading') ? 's-strong' : 's-body'}
          >
            <div className={styles.labelContainer}>
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
                id={`btn-label-${id}`}
                className={styles.btnLabel}
                onClick={event => {
                  if (_.isEqual(event.target, event.currentTarget) && !disabled && !_.isNull(onChange)) {
                    onChange()
                  }
                }}
                aria-hidden={true}
              />
              {_.isEqual(labelType, 'heading') && !disabled && !required && (
                <WppTypography type="s-body" tag="p" className={styles.optional}>
                  (Optional)
                </WppTypography>
              )}
              <div className={styles.tooltip}>
                <FormFieldTooltip helpModal={helpModal} tooltip={tooltipText} />
              </div>
              {isCompletedField && <WppIconAvailableCheckmark backgroundColor="var(--wpp-text-color-success)" />}
            </div>
          </WppTypography>
        </div>
      )}
      {!_.isEmpty(desc) && (
        <WppTypography className={styles.description} type="s-body">
          <span
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          />
        </WppTypography>
      )}
    </>
  )
}

export default FormFieldLabel
