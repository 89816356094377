import { IQuestionnairesState } from 'interfaces/state/IQuestionnairesState'

export const defaultQuestionnairesState: IQuestionnairesState = {
  error: false,
  initLoading: true,
  loading: false,
  openModal: false,
  parentQuestionnaireId: null,
  disableFormSelection: true,
  questionnaireId: null,
  parentQuestionnaireItemId: null,
  projectQuestionnaireId: null,
  questionnaires: [],
  parentQuestionnaires: [],
  showQuestionnaireSelection: false,
  isExistingQuestionnaire: false,
  unauthorizedApp: false,
  unauthorizedQuestionnaire: '',
}
