export const SET_ERROR = 'SET_ERROR'
export const SET_LOADING = 'SET_LOADING'
export const SET_INIT_LOADING = 'SET_INIT_LOADING'
export const SET_STEP = 'SET_STEP'
export const SET_APP_STATUS = 'SET_APP_STATUS'
export const SET_APP = 'SET_APP'
export const SET_REJECTION_INFO = 'SET_REJECTION_INFO'
export const SET_REVIEW = 'SET_REVIEW'
export const SET_REVIEWERS = 'SET_REVIEWERS'
export const SET_STATE = 'SET_STATE'
