import _ from 'lodash'

import styles from 'app/components/review/appStatus/AppStatus.module.scss'
import { WppInlineMessage, WppTooltip } from 'buildingBlocks'
import { QuestionnaireStatus } from 'config/enums'
import { IReview } from 'interfaces/review/IReview'

interface IAppStatusProps {
  /** Review data */
  review: IReview
  /** Application name*/
  appName: string
}

/**
 * App Status component
 * @param {object} props
 * @param {IReview} props.review
 * @param {string} props.appName
 */
const AppStatus: React.FC<IAppStatusProps> = ({ review, appName }: IAppStatusProps): React.ReactElement => {
  if (_.isEqual(review.statusValue, QuestionnaireStatus.DRAFT)) {
    return <></>
  }

  let tooltipMessage = `Sent into review by ${review.issuerFullName} - ${review.issuerEmail}, ${review.requestedAt}`

  if (_.isEqual(review.statusValue, QuestionnaireStatus.APPROVED)) {
    tooltipMessage = `${appName} app has been approved by ${review.reviewerFullName} - ${review.reviewerEmail}, ${review.completedAt}. It was sent into review by ${review.issuerFullName} - ${review.issuerEmail}, ${review.requestedAt}.`
  }

  return (
    <WppTooltip
      text={tooltipMessage}
      component={
        <WppInlineMessage
          size="s"
          message={review.statusStr}
          className={styles.inlineMessage}
          type={_.isEqual(review.statusValue, QuestionnaireStatus.APPROVED) ? 'success' : 'information'}
        />
      }
    />
  )
}

export default AppStatus
