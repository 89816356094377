import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/formField/formFieldComment/FormFieldCommentIcon/FormFieldCommentIcon.module.scss'
import { WppAvatarGroup, WppIconChatMessage } from 'buildingBlocks'
import { QuestionnaireStatus } from 'config/enums'
import { IFieldComment } from 'interfaces/field/fieldComment/IFieldComment'
import { IReview } from 'interfaces/review/IReview'
import FieldCommentHelper from 'utils/formField/FieldCommentHelper'

interface IFormFieldCommentIconProps {
  /** Field comment */
  fieldComment: IFieldComment
  /** Review Object */
  review: IReview | null
  /** Is comment mode enabled*/
  enableCommentMode: boolean | undefined
}

/**
 * Create icon for the field comment
 * @param {object} props
 * @param {IFieldComment} props.fieldComment
 * @param {IReview | null} props.review
 * @param {boolean | undefined} props.enableCommentMode
 */
const FormFieldCommentIcon: React.FC<IFormFieldCommentIconProps> = ({
  fieldComment,
  enableCommentMode,
  review,
}: IFormFieldCommentIconProps): React.ReactElement => {
  return (
    <div slot="trigger-element">
      {_.isEmpty(fieldComment.uniqueUsers) &&
        !_.isEqual(review?.statusValue, QuestionnaireStatus.APPROVED) &&
        enableCommentMode && <WppIconChatMessage className={styles.chatMessageIcon} />}
      {!_.isEmpty(fieldComment.uniqueUsers) && (
        <div className={styles.avatarContainer}>
          <div className={styles.avatar}>
            <div className={styles.arrow} />
            <WppAvatarGroup
              className={styles.avatarGroup}
              maxAvatarsToDisplay={2}
              size="xs"
              withTooltip
              avatars={FieldCommentHelper.getCommentUsers(fieldComment.uniqueUsers)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default FormFieldCommentIcon
