import IOption from 'interfaces/common/IOption'
import { IPayloadAction } from 'interfaces/common/IPayloadAction'
import { IQuestionnairesState } from 'interfaces/state/IQuestionnairesState'
import {
  SET_ERROR,
  SET_INIT_LOADING,
  SET_LOADING,
  SET_OPEN_MODAL,
  SET_PARENT_QUESTIONNAIRES,
  SET_PARENT_QUESTIONNAIRE_ITEM_ID,
  SET_QUESTIONNAIRES,
  SET_QUESTIONNAIRE_ID,
  SET_STATE,
  SET_QUESTIONNAIRE_UNAUTHORIZED,
  SET_APP_UNAUTHORIZED,
} from 'pages/store/types'

export const questionnairesReducer = (state: IQuestionnairesState, action: IPayloadAction): IQuestionnairesState => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SET_QUESTIONNAIRES:
      return {
        ...state,
        ...action.payload.state,
        questionnaires: action.payload.questionnaires,
      }
    case SET_QUESTIONNAIRE_ID:
      return {
        ...state,
        ...action.payload.state,
        questionnaireId: action.payload.questionnaireId,
        parentQuestionnaireId: action.payload.parentQuestionnaireId,
      }
    case SET_PARENT_QUESTIONNAIRE_ITEM_ID:
      return {
        ...state,
        ...action.payload.state,
        parentQuestionnaireItemId: action.payload.parentQuestionnaireItemId,
      }
    case SET_PARENT_QUESTIONNAIRES:
      return {
        ...state,
        ...action.payload.state,
        parentQuestionnaires: action.payload.questionnaires,
      }
    case SET_INIT_LOADING: {
      return {
        ...state,
        initLoading: action.payload,
      }
    }
    case SET_OPEN_MODAL: {
      return {
        ...state,
        openModal: action.payload,
      }
    }
    case SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case SET_APP_UNAUTHORIZED:
      return {
        ...state,
        unauthorizedApp: action.payload,
      }
    case SET_QUESTIONNAIRE_UNAUTHORIZED:
      return {
        ...state,
        unauthorizedQuestionnaire: action.payload,
      }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    default:
      return state
  }
}

export const setLoading = (loading: boolean) => ({ type: SET_LOADING, payload: loading })

export const setInitLoading = (loading: boolean) => ({ type: SET_INIT_LOADING, payload: loading })

export const setQuestionnaires = (questionnaires: IOption[], state?: IQuestionnairesState) => ({
  type: SET_QUESTIONNAIRES,
  payload: {
    questionnaires,
    state,
  },
})

export const setQuestionnaire = (
  {
    questionnaireId,
    parentQuestionnaireId,
  }: {
    questionnaireId: string | null
    parentQuestionnaireId: string | null
  },
  state?: IQuestionnairesState,
) => ({
  type: SET_QUESTIONNAIRE_ID,
  payload: {
    questionnaireId,
    parentQuestionnaireId,
    state,
  },
})

export const setParentQuestionnaires = (questionnaires: IOption[], state?: IQuestionnairesState) => ({
  type: SET_PARENT_QUESTIONNAIRES,
  payload: {
    questionnaires,
    state,
  },
})

export const setParentQuestionnaireItem = (parentQuestionnaireItemId: string | null, state?: IQuestionnairesState) => ({
  type: SET_PARENT_QUESTIONNAIRE_ITEM_ID,
  payload: {
    parentQuestionnaireItemId,
    state,
  },
})

export const setError = (flag: boolean) => ({ type: SET_ERROR, payload: flag })

export const setOpenModal = (flag: boolean) => ({ type: SET_OPEN_MODAL, payload: flag })

export const setState = (state: IQuestionnairesState) => ({ type: SET_STATE, payload: state })

export const setAppUnauthorized = (flag: boolean) => ({ type: SET_APP_UNAUTHORIZED, payload: flag })
export const setQuestionnaireUnauthorized = (message: string) => ({
  type: SET_QUESTIONNAIRE_UNAUTHORIZED,
  payload: message,
})
