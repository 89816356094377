import _ from 'lodash'
import React from 'react'

import styles from 'app/components/stepper/Stepper.module.scss'
import { WppButton } from 'buildingBlocks'
import { AppAction, StepAction, ToastMessageType } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'

interface IStepperProps {
  /** IApp interface */
  app: IApp
  /** Handle app Submit/Save Progress/Cancel event*/
  handleAppAction: (appAction: string, callback: (message: ToastMessageType) => void, displayMessage?: boolean) => void
  /** Handle Next/Previous step event*/
  handleStep: (stepNumber: number, stepAction: StepAction, nextCategory: ICategory) => void
  /** Active step*/
  activeStep: number
  /** if user has editor access*/
  isAppEditor: boolean
}

/**
 * Stepper
 * @param {object} props
 * @param {IApp[]} props.app
 * @param {Function} props.handleAppAction
 * @param {Function} props.handleStep
 * @param {boolean} props.isAppEditor
 * @param {number} props.activeStep
 */
const Stepper: React.FC<IStepperProps> = ({
  app,
  handleAppAction,
  handleStep,
  activeStep,
  isAppEditor,
}: IStepperProps): React.ReactElement => {
  const prevCategory: any = _.find(app.categories, { categoryIndex: activeStep - 1 })
  const nextCategory: any = _.find(app.categories, { categoryIndex: activeStep + 1 })

  return (
    <div className={styles.stepContainer}>
      <WppButton id="btn-cancel" onClick={() => handleAppAction(AppAction.CANCEL, () => {})} variant="secondary">
        {isAppEditor ? 'Cancel' : 'Exit'}
      </WppButton>
      <WppButton
        id="btn-back"
        onClick={() => handleStep(activeStep - 1, StepAction.PREVIOUS, prevCategory)}
        disabled={_.isEqual(activeStep, 1)}
      >
        Previous Step
      </WppButton>
      <WppButton
        id="btn-next"
        onClick={() => handleStep(activeStep + 1, StepAction.NEXT, nextCategory)}
        className={styles.btnNextSubmit}
        disabled={_.isEqual(app.categories.length, activeStep)}
      >
        Next Step
      </WppButton>
    </div>
  )
}

export default Stepper
