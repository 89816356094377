import { Page, Text, View, Document, PDFViewer, Font, Image } from '@react-pdf/renderer'
import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import Category from 'app/components/action/pdfViewer/category'
import cssStyles from 'app/components/action/pdfViewer/PdfViewer.module.scss'
import styles from 'app/components/action/pdfViewer/styles'
import { WppActionButton, WppButton, WppFullScreenModal, WppIconExportFile } from 'buildingBlocks'
import { PDF_FONT_FAMILY_NAME, PDF_FONTS_SRC } from 'config/constants'
import { AppAction, ToastMessageType } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import PDFHelper from 'utils/form/PDFHelper'

interface IPdfViewerProps {
  /** IApp interface */
  app: IApp
  /** Handle app Submit/Save Progress/Cancel event*/
  handleAppAction: (appAction: string, callback: (message: ToastMessageType) => void, displayMessage?: boolean) => void
}

Font.register({
  family: PDF_FONT_FAMILY_NAME,
  fonts: PDF_FONTS_SRC,
})

/**
 * PDF Viewer component
 * @param {object} props
 * @param {IApp} props.app
 * @param {Function} props.handleAppAction
 */
const PdfViewer: React.FC<IPdfViewerProps> = ({ app, handleAppAction }: IPdfViewerProps): React.ReactElement => {
  const { osContext } = useOs()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [logoUrl, setLogoUrl] = useState<string>('')
  const logoOriginal = osContext.tenant.logoOriginal?.url ?? ''

  useEffect(() => {
    if (logoOriginal) {
      setLoading(true)
      PDFHelper.getBase64Image(logoOriginal, (data: string) => {
        setLogoUrl(data)
        setLoading(false)
      })
    }
  }, [logoOriginal])

  const handlePdfViewer = async () => {
    handleAppAction(AppAction.PDF_VIEWER, (message: ToastMessageType) => {
      if (!_.isEqual(message, ToastMessageType.ERROR)) setOpen(true)
    })
  }

  return (
    <>
      <WppActionButton loading={loading} data-testid="btn-pdf" id="btn-pdf" onClick={handlePdfViewer}>
        <WppIconExportFile color="var(--wpp-brand-color)" />
      </WppActionButton>
      {!loading && (
        <WppFullScreenModal open={open} className={cssStyles.modal}>
          <h3 slot="header">PDF Viewer</h3>
          <div slot="body" className={cssStyles.modalBody}>
            <PDFViewer width="100%" height="100%">
              <Document title={app.appName}>
                <Page size="A4" style={styles.page}>
                  {!_.isEmpty(logoUrl) && (
                    <Image src={`data:image/png;base64,${logoUrl}`} style={styles.tenantLogo} fixed />
                  )}
                  <Text style={styles.header}>{app.appName}</Text>
                  <View style={styles.categoryContainer}>
                    {app.categories.map((category: ICategory) => (
                      <Category key={category.id} category={category} />
                    ))}
                  </View>
                </Page>
              </Document>
            </PDFViewer>
          </div>
          <div slot="actions" className={cssStyles.modalActions}>
            <WppButton variant="secondary" id="btn-close" onClick={() => setOpen(false)}>
              Close
            </WppButton>
          </div>
        </WppFullScreenModal>
      )}
    </>
  )
}

export default PdfViewer
