import { AxiosResponse } from 'axios'
import _ from 'lodash'

import { deleteReviewAPIPath, getReviewAPIPath, sendToReviewAPIPath, updateReviewAPIPath } from 'config/apiPaths'
import { QuestionnaireStatus, QuestionnaireStatusValue, TagVariant } from 'config/enums'
import { IReview } from 'interfaces/review/IReview'
import { IReviewNotification } from 'interfaces/review/IReviewNotification'
import AxiosService from 'lib/AxiosService'
import SharedHelper from 'utils/SharedHelper'

export default class ReviewHelper {
  public getReviewData(review: IReview, isReviewer: boolean = false) {
    if (!review) return null
    let statusVariant: TagVariant = TagVariant.NEUTRAL
    statusVariant = _.isEqual(review.statusValue, QuestionnaireStatus.APPROVED) ? TagVariant.POSITIVE : statusVariant
    statusVariant = _.isEqual(review.statusValue, QuestionnaireStatus.SENT_FOR_APPROVAL)
      ? TagVariant.WARNING
      : statusVariant
    statusVariant =
      _.isEqual(review.statusValue, QuestionnaireStatus.DRAFT) && !_.isEmpty(review.statusReason)
        ? TagVariant.NEGATIVE
        : statusVariant

    let statusStr = 'Draft'
    statusStr = _.isEqual(review.statusValue, QuestionnaireStatus.APPROVED)
      ? QuestionnaireStatusValue.APPROVED
      : statusStr
    statusStr = _.isEqual(review.statusValue, QuestionnaireStatus.SENT_FOR_APPROVAL)
      ? QuestionnaireStatusValue.SENT_FOR_APPROVAL
      : statusStr
    statusStr =
      _.isEqual(review.statusValue, QuestionnaireStatus.DRAFT) && !_.isEmpty(review.statusReason)
        ? QuestionnaireStatusValue.REJECTED
        : statusStr

    const reviewData: IReview = {
      ...review,
      issuerFullName: `${review.issuerFirstname} ${review.issuerLastname}`,
      reviewerFullName: `${review.reviewerFirstname} ${review.reviewerLastname}`,
      completedAt: SharedHelper.getFormattedDateInUTC(review.completedAt),
      requestedAt: SharedHelper.getFormattedDateInUTC(review.requestedAt),
      statusVariant,
      statusStr,
      isReviewer,
    }
    return reviewData
  }

  /**
   * Get review details
   * @param {string} accessToken
   * @param {string} userEmail
   * @param {string} projectQuestionnaireId
   * @param {string} tenantId
   * @returns {Promise<IReview | null>}
   */
  public async getReview(
    accessToken: string,
    userEmail: string,
    projectQuestionnaireId: string,
    tenantId: string,
  ): Promise<IReview | null> {
    const axiosService = new AxiosService(accessToken)
    const review: AxiosResponse<IReview> = await axiosService.get(getReviewAPIPath(projectQuestionnaireId), tenantId)

    if (!review.data) {
      return null
    }

    return this.getReviewData(review.data, _.isEqual(_.toLower(review.data.reviewerEmail), _.toLower(userEmail)))
  }

  /**
   * Get review details
   * @param {string} accessToken
   * @param {string} reviewerEmail
   * @param {string} projectQuestionnaireId
   * @param {string} tenantId
   * @param {string} appInstanceId
   * @param {boolean} isExistingReview
   * @returns {Promise<IReview | null>}
   */
  public async sendToReview(
    accessToken: string,
    reviewerEmail: string,
    projectQuestionnaireId: string,
    tenantId: string,
    appInstanceId: string,
    isExistingReview: boolean,
  ): Promise<IReviewNotification | null> {
    const axiosService = new AxiosService(accessToken)
    let review: AxiosResponse<IReviewNotification | null>
    if (isExistingReview) {
      review = await axiosService.patch(sendToReviewAPIPath(projectQuestionnaireId), { reviewerEmail }, tenantId, {
        headers: {
          'X-App-Instance-Id': appInstanceId,
        },
      })
    } else {
      review = await axiosService.post(sendToReviewAPIPath(), { reviewerEmail, projectQuestionnaireId }, tenantId, {
        headers: {
          'X-App-Instance-Id': appInstanceId,
        },
      })
    }

    if (!review.data) {
      return null
    }

    return review.data
  }

  /**
   * Update review status
   * @param {string} accessToken
   * @param {string} projectQuestionnaireId
   * @param {string} statusValue
   * @param {string} statusReason
   * @param {string} tenantId
   * @param {string} appInstanceId
   * @returns {Promise<IReview | null>}
   */
  public async updateReviewStatus(
    accessToken: string,
    projectQuestionnaireId: string,
    statusValue: string,
    statusReason: string,
    tenantId: string,
    appInstanceId: string,
  ): Promise<IReviewNotification | null> {
    const axiosService = new AxiosService(accessToken)
    const review: AxiosResponse<IReviewNotification> = await axiosService.patch(
      updateReviewAPIPath(projectQuestionnaireId),
      {
        statusValue,
        statusReason,
      },
      tenantId,
      {
        headers: {
          'X-App-Instance-Id': appInstanceId,
        },
      },
    )

    if (!review.data) {
      return null
    }

    return review.data
  }
  /**
   * Delete Review
   * @param {string} accessToken
   * @param {string} projectQuestionnaireId
   * @param {string} tenantId
   * @param {string} appInstanceId
   * @returns {Promise<boolean>}
   */
  public async deleteReview(
    accessToken: string,
    projectQuestionnaireId: string,
    tenantId: string,
    appInstanceId: string,
  ): Promise<boolean> {
    const axiosService = new AxiosService(accessToken)
    const response: AxiosResponse<{ notificationSubmitted: boolean }> = await axiosService.delete(
      deleteReviewAPIPath(projectQuestionnaireId),
      tenantId,
      {
        headers: {
          'X-App-Instance-Id': appInstanceId,
        },
      },
    )
    return response.data.notificationSubmitted
  }
}
