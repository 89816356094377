import { View } from '@react-pdf/renderer'
import _ from 'lodash'
import React, { Fragment } from 'react'

import FieldBuilder from 'app/components/action/pdfViewer/field/fieldBuilder'
import styles from 'app/components/action/pdfViewer/field/fieldChildren/styles'
import { FieldType } from 'config/enums'
import IField from 'interfaces/field/IField'
import FormFieldHelper from 'utils/formField/FormFieldHelper'
import FormFieldValidation from 'utils/formField/FormFieldValidation'
import SharedHelper from 'utils/SharedHelper'

interface IFieldChildrenProps {
  /** IField interface */
  field: IField
  /** Option value */
  optionValue: string
}

const formFieldHelper = new FormFieldHelper()
const sharedHelper = new SharedHelper()

/**
 * Create children field
 * @param {object} props
 * @param {IField} props.field
 * @param {any} props.optionValue
 */
const FieldChildren: React.FC<IFieldChildrenProps> = ({
  field,
  optionValue,
}: IFieldChildrenProps): React.ReactElement => {
  const { value } = field
  const children: IField[] = formFieldHelper.getChildren(field, value)

  return (
    <View style={_.isEqual(field.type, FieldType.RADIO) ? styles.singleValChildren : styles.multiValChildren}>
      {children.map((childField: IField) => {
        if (sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(optionValue)])) {
          return FormFieldValidation.isEmptyField(childField) ? (
            <Fragment key={childField.id} />
          ) : (
            <FieldBuilder key={childField.id} field={childField} />
          )
        }
        return <Fragment key={childField.id} />
      })}
    </View>
  )
}

export default FieldChildren
