import _ from 'lodash'
import React from 'react'

import Field from 'app/components/form//formBuilder/formField/Field'
import styles from 'app/components/form/formField/formFieldChildren/FormFieldChildren.module.scss'
import { IFieldComment } from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { IReview } from 'interfaces/review/IReview'
import FormFieldHelper from 'utils/formField/FormFieldHelper'
import SharedHelper from 'utils/SharedHelper'

interface IFormFieldChildrenProps {
  /** IField interface */
  field: IField
  /** Is comment mode enabled*/
  enableCommentMode: boolean
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Option value */
  optionValue: any
  /** Review Object */
  review: IReview | null
  /** Handle field comment */
  handleComment: (field: IField, value: string) => void
  /** If user is project member*/
  isProjectMember: boolean
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
}

const formFieldHelper = new FormFieldHelper()
const sharedHelper = new SharedHelper()

/**
 * Create counter field
 * @param {object} props
 * @param {IField} props.field
 * @param {any} props.optionValue
 * @param {boolean} props.isProjectMember
 * @param {IReview | null} props.review
 * @param {boolean} props.enableCommentMode
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 * @param {(field: IField, value: string)} props.handleComment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 */
const FormFieldChildren: React.FC<IFormFieldChildrenProps> = ({
  field,
  optionValue,
  handleChange,
  handleComment,
  enableCommentMode,
  review,
  setFormFieldComments,
  isProjectMember,
}: IFormFieldChildrenProps): React.ReactElement => {
  const { value } = field
  const children: IField[] = formFieldHelper.getChildren(field, value)
  return (
    <div className={styles.container}>
      {children.map((childField: IField) => {
        if (sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(optionValue)])) {
          return (
            <div className={styles.marginTop} key={childField.id}>
              <Field
                handleComment={handleComment}
                field={childField}
                handleChange={handleChange}
                enableCommentMode={enableCommentMode}
                review={review}
                isChildField
                setFormFieldComments={setFormFieldComments}
                isProjectMember={isProjectMember}
              />
            </div>
          )
        }
        return <span key={childField.id} />
      })}
    </div>
  )
}

export default FormFieldChildren
