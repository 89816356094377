import { AxiosResponse } from 'axios'
import _ from 'lodash'

import {
  createProjectQuestionnaireAPIPath,
  saveFormDataAPIPath,
  updateProjectQuestionnaireAPIPath,
} from 'config/apiPaths'
import { FieldType } from 'config/enums'
import ICategory from 'interfaces/category/ICategory'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'
import IFieldRequest from 'interfaces/field/IFieldRequest'
import { IRepeatableField } from 'interfaces/field/repeatable/IRepeatableField'
import IFile from 'interfaces/file/IFile'
import IProjectQuestionnaire from 'interfaces/projectQuestionnaire/IProjectQuestionnaire'
import AxiosService from 'lib/AxiosService'
import FormFieldHelper from 'utils/formField/FormFieldHelper'
import FormFieldValidation from 'utils/formField/FormFieldValidation'

export default class SubmitHelper {
  private formFieldHelper: FormFieldHelper

  /**
   *  Creates an instance of FormHelper
   */
  constructor() {
    this.formFieldHelper = new FormFieldHelper()
  }

  /**
   * Return display value for submit
   * @param {IField} field
   * @param {any} fieldValue
   * @returns {boolean}
   */
  isValueChange(field: IField, fieldValue: any): boolean {
    let isValueChange: boolean = false

    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA:
      case FieldType.RADIO:
      case FieldType.DATE_PICKER:
      case FieldType.SELECT:
      case FieldType.MARKET: {
        isValueChange = !_.isEqual(fieldValue, field.initialValue)
        break
      }
      case FieldType.FILE_UPLOAD: {
        isValueChange = !_.isEqual(
          _.sortBy(fieldValue.map((file: IFile) => file.id)),
          _.sortBy(field.initialValue.map((file: IFile) => file.id)),
        )
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.USER:
      case FieldType.AUTOCOMPLETE: {
        isValueChange = !_.isEqual(
          _.sortBy(fieldValue.map((option: IOption) => option.id)),
          _.sortBy(field.initialValue.map((option: IOption) => option.id)),
        )
        break
      }
    }
    return isValueChange
  }

  /**
   * Build Save Request Data
   * @param {IField} field
   * @param {string} fieldValue
   * @param {string} displayValue
   * @returns {IFieldRequest}
   */
  buildSaveRequestData(field: IField, fieldValue: any, displayValue: string): IFieldRequest {
    let val: { value: string } | null = {
      value: fieldValue,
    }

    if (_.isNull(fieldValue)) {
      val = null
    }

    return {
      [field.formFieldId]: {
        displayValue,
        value: val,
      },
    }
  }

  /**
   * Return select value for submit
   * @param {IField} field
   * @returns {any}
   */
  getSelectValue(field: IField) {
    if (!field.value) {
      return ''
    }
    let value = _.isArray(field.value) ? field.value : [field.value]
    value = value.map((val: any) => _.toString(val))

    return _.map(
      field.fieldConfig.options.filter((option: IOption) => !_.isEqual(value.indexOf(_.toString(option.id)), -1)),
      (option: IOption) => option.label,
    ).join(', ')
  }

  /**
   * Return display value for submit
   * @param {IField} field
   * @returns {any}
   */
  getSubmitDisplayValues(field: IField): any {
    let value = ''

    if (_.gt(field.maxRepeatableAmount, 0)) {
      return field.repeatableFields.map((repeatableField: IRepeatableField) => repeatableField.value).join(', ')
    }

    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA:
      case FieldType.DATE_PICKER: {
        value = field.value
        break
      }
      case FieldType.FILE_UPLOAD: {
        value = field.value.map(({ file }: any) => file.url ?? '').join(', ')
        break
      }
      case FieldType.RADIO:
      case FieldType.SELECT:
      case FieldType.MARKET: {
        value = this.getSelectValue(field)
        break
      }
      case FieldType.CURRENCY: {
        value = `${field.value.currencyAmount} ${field.value.currencyType}`
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE:
      case FieldType.USER: {
        value = field.value
          .map((option: IOption) => {
            const subLabel = !option.subLabel ? '' : ` - ${option.subLabel}`
            return `${option.label}${subLabel}`
          })
          .join(', ')
        break
      }
    }
    return value
  }

  /**
   * Get field value
   * @param {IField} field
   */
  getFieldValue(field: IField) {
    const isEmptyField = FormFieldValidation.isEmptyField(field)
    const fieldValue = isEmptyField ? null : field.value
    if (field.maxRepeatableAmount && _.gt(field.maxRepeatableAmount, 0)) {
      return {
        repeatable: field.repeatableFields,
      }
    }
    return fieldValue
  }

  /**
   * Save Form Progress
   * @param {ICategory} category
   * @param {string} tenantId
   * @param {string} projectId
   * @returns {IResponse[]}
   */
  onSaveProgress(category: ICategory) {
    let data = {}
    for (let form of category.forms) {
      // eslint-disable-next-line no-loop-func
      form.fields.forEach((field: IField) => {
        if (field.isValid && field.touched) {
          const Value = field.isHidden ? this.formFieldHelper.getDefaultValue(field) : this.getFieldValue(field)
          const DisplayValue = field.isHidden ? '' : this.getSubmitDisplayValues(field)
          const fieldData = this.buildSaveRequestData(field, Value, DisplayValue)

          data = _.merge(data, fieldData)
        }
        field.children.forEach((childField: IField) => {
          if (childField.isValid && childField.touched) {
            const ChildValue = childField.isHidden
              ? this.formFieldHelper.getDefaultValue(childField)
              : this.getFieldValue(childField)
            const ChildDisplayValue = childField.isHidden ? '' : this.getSubmitDisplayValues(childField)
            const childFieldData = this.buildSaveRequestData(childField, ChildValue, ChildDisplayValue)
            data = _.merge(data, childFieldData)
          }
        })
      })
    }

    return data
  }

  /**
   * Get form data in chunk
   * @param {{ [key: string]: IFieldRequest }} formData
   * @param {number} dataCount
   * @returns {{ [key: string]: IFieldRequest }[]}
   */
  async getFormDataInChunk({
    accessToken,
    itemId,
    projectId,
    tenantId,
    formData,
    formDataSize,
    errorCount,
  }: {
    accessToken: string
    itemId: string
    projectId: string
    tenantId: string
    formData: { [key: string]: IFieldRequest }
    formDataSize: number
    errorCount: number
  }): Promise<void> {
    const values = Object.values(formData)
    const chunkFormData: { [key: string]: IFieldRequest }[] = []
    let counter = 0
    let fieldData: { [key: string]: IFieldRequest } = {}

    for (let key in formData) {
      if (!_.isEqual(counter, 0) && _.isEqual(counter % formDataSize, 0)) {
        chunkFormData.push(fieldData)
        fieldData = {}
      }
      fieldData[key] = values[counter]
      counter++
    }

    chunkFormData.push(fieldData)
    const axiosService = new AxiosService(accessToken)

    let promises: Promise<void>[] = []
    chunkFormData.forEach((formFieldContent: { [key: string]: IFieldRequest }) => {
      promises.push(
        axiosService.post(
          saveFormDataAPIPath(),
          {
            formFieldContent,
            projectId,
            itemId,
          },
          tenantId,
        ),
      )
    })

    try {
      await Promise.all(promises)
    } catch {
      if (_.gt(errorCount, 4)) {
        throw new Error()
      }
      errorCount += 1
      await this.getFormDataInChunk({
        accessToken,
        errorCount,
        formData,
        itemId,
        projectId,
        formDataSize: Math.ceil(formDataSize / 2),
        tenantId,
      })
    }
  }

  /**
   * Save data
   * @param {string} accessToken
   * @param {{ [key: string]: IFieldRequest }} formData
   * @returns {Promise<void>}
   */
  saveToDb = async (
    accessToken: string,
    itemId: string,
    projectId: string,
    tenantId: string,
    formData: { [key: string]: IFieldRequest },
  ): Promise<void> => {
    if (!_.isEmpty(formData)) {
      await this.getFormDataInChunk({
        accessToken,
        errorCount: 0,
        formData,
        itemId,
        projectId,
        formDataSize: 20,
        tenantId,
      })
    }
  }

  /**
   * Save Project Questionnaire
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {{ parentItemId: string | null }} formData
   * @param {string} projectQuestionnaireId
   * @param {boolean} isExistingQuestionnaire
   * @returns {Promise<void>}
   */
  onSaveProjectQuestionnaire = async (
    accessToken: string,
    tenantId: string,
    formData: IProjectQuestionnaire,
    projectQuestionnaireId: string | null,
    isExistingQuestionnaire: boolean = false,
  ): Promise<string> => {
    const axiosService = new AxiosService(accessToken)
    if (isExistingQuestionnaire && projectQuestionnaireId) {
      await axiosService.patch(
        updateProjectQuestionnaireAPIPath(projectQuestionnaireId),
        {
          parentItemId: formData.parentItemId,
        },
        tenantId,
      )
      return projectQuestionnaireId
    }
    const response: AxiosResponse<IProjectQuestionnaire> = await axiosService.post(
      createProjectQuestionnaireAPIPath(),
      formData,
      tenantId,
    )
    return response.data.id ?? ''
  }
}
