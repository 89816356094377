import IApp from 'interfaces/app/IApp'
import { IAppStatus } from 'interfaces/app/IAppStatus'
import { IPayloadAction } from 'interfaces/common/IPayloadAction'
import { IRejectInfo } from 'interfaces/review/IRejectInfo'
import { IReview } from 'interfaces/review/IReview'
import { IReviewer } from 'interfaces/review/IReviewer'
import { IQuestionnaireState } from 'interfaces/state/IQuestionnaireState'
import {
  SET_ERROR,
  SET_APP,
  SET_APP_STATUS,
  SET_LOADING,
  SET_STEP,
  SET_REJECTION_INFO,
  SET_REVIEWERS,
  SET_REVIEW,
  SET_STATE,
} from 'pages/questionnaire/store/types'

export const questionnaireReducer = (state: IQuestionnaireState, action: IPayloadAction): IQuestionnaireState => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SET_STEP:
      return {
        ...state,
        activeStep: action.payload,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_APP_STATUS:
      return {
        ...state,
        ...action.payload.state,
        appStatus: {
          ...state.appStatus,
          ...action.payload,
        },
      }
    case SET_REJECTION_INFO:
      return {
        ...state,
        rejectInfo: {
          ...state.rejectInfo,
          ...action.payload,
        },
      }
    case SET_REVIEWERS:
      return {
        ...state,
        reviewers: action.payload,
      }
    case SET_REVIEW:
      return {
        ...state,
        review: action.payload,
      }
    case SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case SET_APP: {
      return {
        ...state,
        app: {
          ...state.app,
          ...action.payload,
        },
      }
    }
    default:
      return state
  }
}

export const setLoading = (loading: boolean) => ({ type: SET_LOADING, payload: loading })
export const setAppStatus = (aStatus: IAppStatus) => ({ type: SET_APP_STATUS, payload: aStatus })
export const setStep = (step: number) => ({ type: SET_STEP, payload: step })
export const setApp = (updateApp: IApp | null) => ({ type: SET_APP, payload: updateApp })
export const setError = (err: boolean) => ({ type: SET_ERROR, payload: err })
export const setReviewers = (data: IReviewer[]) => ({ type: SET_REVIEWERS, payload: data })
export const setRejectionInfo = (data: IRejectInfo) => ({ type: SET_REJECTION_INFO, payload: data })
export const setReview = (data: IReview | null) => ({ type: SET_REVIEW, payload: data })
export const setState = (state: IQuestionnaireState) => ({ type: SET_STATE, payload: state })
