import { FullscreenAppContext } from '@wppopen/core'
import { AxiosError } from 'axios'
import HTTP_STATUS_CODES from 'http-status-codes'
import _ from 'lodash'

import { TOAST_DURATION } from 'config/constants'
import { ToastMessageType } from 'config/enums'
import IOption from 'interfaces/common/IOption'
import { IProject } from 'interfaces/project/IProject'
import IProjectQuestionnaire from 'interfaces/projectQuestionnaire/IProjectQuestionnaire'
import { IQuestionnairesState } from 'interfaces/state/IQuestionnairesState'
import {
  setError,
  setLoading,
  setQuestionnaire,
  setQuestionnaires,
  setState,
  setAppUnauthorized,
  setQuestionnaireUnauthorized,
  setInitLoading,
} from 'pages/store/actions'
import QuestionnaireHelper from 'utils/form/QuestionnaireHelper'
import SubmitHelper from 'utils/form/SubmitHelper'
import PermissionHelper from 'utils/permission/PermissionHelper'
import ProjectHelper from 'utils/project/ProjectHelper'
import SharedHelper from 'utils/SharedHelper'

export default class ApplicationHelper {
  /**
   * handle Questionnaire Selection
   * @param {string} accessToken
   * @param {FullscreenAppContext} osContext
   * @param {IOption} option
   * @param {IQuestionnairesState} state
   * @param {Function} dispatch
   * @returns {Promise<void>}
   */
  public static readonly handleQuestionnaireSelection = async (
    accessToken: string,
    osContext: FullscreenAppContext,
    option: IOption,
    state: IQuestionnairesState,
    dispatch: Function,
  ): Promise<void> => {
    dispatch(setLoading(true))
    const { TENANT_ID, PROJECT_ID } = SharedHelper.getAppConfigurationData(osContext)

    let parentQuestionnairesList: IOption[] = []

    if (option.parentId) {
      parentQuestionnairesList = await QuestionnaireHelper.getParentQuestionnaires(
        accessToken,
        TENANT_ID,
        option.parentId,
        PROJECT_ID,
      )
    }

    dispatch(
      setQuestionnaire(
        {
          questionnaireId: option.id,
          parentQuestionnaireId: option.parentId,
        },
        {
          ...state,
          parentQuestionnaires: parentQuestionnairesList,
          parentQuestionnaireItemId: null,
          loading: false,
        },
      ),
    )
  }

  /**
   * Save/Update project questionnaire
   * @param {string} accessToken
   * @param {FullscreenAppContext} osContext
   * @param {IQuestionnairesState} state
   * @param {Function} dispatch
   * @param {Function} showToast
   * @returns {Promise<void>}
   */
  public static readonly onSaveProjectQuestionnaire = async (
    accessToken: string,
    osContext: FullscreenAppContext,
    state: IQuestionnairesState,
    dispatch: Function,
    showToast: Function,
  ): Promise<void> => {
    const { questionnaireId, parentQuestionnaireItemId, projectQuestionnaireId, isExistingQuestionnaire } = state
    dispatch(
      setState({
        ...state,
        openModal: false,
        loading: true,
      }),
    )

    try {
      if (!questionnaireId) {
        return
      }
      const helper = new SubmitHelper()
      const { TENANT_ID, PROJECT_ID, ITEM_ID } = SharedHelper.getAppConfigurationData(osContext)
      const projectQId = await helper.onSaveProjectQuestionnaire(
        accessToken,
        TENANT_ID,
        {
          projectId: PROJECT_ID,
          questionnaireId,
          itemId: ITEM_ID,
          parentItemId: parentQuestionnaireItemId,
        },
        projectQuestionnaireId,
        isExistingQuestionnaire,
      )
      dispatch(
        setState({
          ...state,
          showQuestionnaireSelection: false,
          loading: false,
          projectQuestionnaireId: projectQId,
          openModal: false,
        }),
      )
    } catch {
      dispatch(setLoading(false))
      showToast({
        header: 'Oops! Something went wrong',
        message:
          'Your data has not been saved. Please try again later or contact customer support if the problem persists.',
        type: ToastMessageType.ERROR,
        duration: TOAST_DURATION,
      })
    }
  }

  /**
   * Handle parent form
   * @param {string} accessToken
   * @param {FullscreenAppContext} osContext
   * @param {IQuestionnairesState} state
   * @param {IProjectQuestionnaire} questionnaireData
   * @param {Function} dispatch
   * @returns {Promise<void>}
   */
  public static readonly handleParentForm = async (
    accessToken: string,
    osContext: FullscreenAppContext,
    state: IQuestionnairesState,
    questionnaireData: IProjectQuestionnaire,
    dispatch: Function,
  ): Promise<void> => {
    const { parentQuestionnaireId } = state
    const { TENANT_ID, PROJECT_ID } = SharedHelper.getAppConfigurationData(osContext)
    const projectQuesId = questionnaireData.id ?? null

    if (questionnaireData.parentItemId) {
      dispatch(
        setQuestionnaire(
          {
            questionnaireId: questionnaireData.questionnaireId,
            parentQuestionnaireId: parentQuestionnaireId,
          },
          {
            ...state,
            showQuestionnaireSelection: false,
            initLoading: false,
            projectQuestionnaireId: projectQuesId,
          },
        ),
      )
      return
    }

    let parentQuestionnairesList: IOption[] = []
    let ques = null
    try {
      ques = await QuestionnaireHelper.getQuestionnaireById(accessToken, osContext, questionnaireData.questionnaireId)
    } catch (err) {
      const error: AxiosError = err as AxiosError
      dispatch(setInitLoading(false))
      const statusCode = error.response?.status
      if (_.isEqual(statusCode, HTTP_STATUS_CODES.FORBIDDEN)) {
        const responseData: any = error.response?.data
        const message: string = `${responseData.detail.name} is no longer available. Please open a support ticket.`
        dispatch(setQuestionnaireUnauthorized(message))
        return
      }
      if (!_.isEqual(statusCode, HTTP_STATUS_CODES.NOT_FOUND)) {
        dispatch(setError(true))
        return
      }
    }

    if (!ques) return

    if (_.isNull(ques.parentQuestionnaireId)) {
      dispatch(
        setQuestionnaire(
          {
            questionnaireId: questionnaireData.questionnaireId,
            parentQuestionnaireId: parentQuestionnaireId,
          },
          {
            ...state,
            showQuestionnaireSelection: false,
            initLoading: false,
            projectQuestionnaireId: projectQuesId,
          },
        ),
      )
      return
    }
    if (ques.parentQuestionnaireId) {
      parentQuestionnairesList = await QuestionnaireHelper.getParentQuestionnaires(
        accessToken,
        TENANT_ID,
        ques.parentQuestionnaireId || '',
        PROJECT_ID,
      )
    }

    let showQuesSelection: boolean =
      (!_.isEmpty(parentQuestionnairesList) && _.isNull(questionnaireData.parentItemId)) || _.isNull(projectQuesId)

    dispatch(
      setQuestionnaire(
        {
          questionnaireId: questionnaireData.questionnaireId,
          parentQuestionnaireId: ques.parentQuestionnaireId,
        },
        {
          ...state,
          parentQuestionnaires: parentQuestionnairesList,
          parentQuestionnaireItemId: questionnaireData.parentItemId,
          initLoading: false,
          showQuestionnaireSelection: showQuesSelection,
          questionnaires: [
            {
              id: questionnaireData.questionnaireId,
              label: ques.name,
            },
          ],
          isExistingQuestionnaire: true,
          disableFormSelection: true,
          projectQuestionnaireId: projectQuesId,
        },
      ),
    )
  }

  /**
   * Load initial data
   * @param {string} accessToken
   * @param {IQuestionnairesState} state
   * @param {FullscreenAppContext} osContext
   * @param {Function} dispatch
   * @returns {Promise<void>}
   */
  public static readonly initLoad = async (
    accessToken: string,
    state: IQuestionnairesState,
    osContext: FullscreenAppContext,
    dispatch: Function,
  ): Promise<void> => {
    try {
      const { PROJECT_ID, TENANT_ID, USER_EMAIL } = SharedHelper.getAppConfigurationData(osContext)
      const projectInfo: IProject | null = await ProjectHelper.getProjectInfo(
        accessToken,
        PROJECT_ID,
        TENANT_ID,
        USER_EMAIL,
      )
      const hasAppEditorPermission = PermissionHelper.hasAppEditor(osContext.permissions) && !_.isNull(projectInfo)
      let selectedQuestionnaire: IProjectQuestionnaire | null = null
      try {
        selectedQuestionnaire = await QuestionnaireHelper.getSelectedQuestionnaire(accessToken, osContext)
      } catch (err) {
        const error = err as AxiosError
        if (_.isEqual(error.response?.status, HTTP_STATUS_CODES.FORBIDDEN)) {
          dispatch(setAppUnauthorized(true))
          return
        }
      }

      if (_.isNull(selectedQuestionnaire)) {
        const questionnairesList = await QuestionnaireHelper.getQuestionnaires(accessToken, osContext)
        dispatch(
          setQuestionnaires(questionnairesList, {
            ...state,
            showQuestionnaireSelection: true,
            disableFormSelection: !hasAppEditorPermission,
            initLoading: false,
          }),
        )
        return
      }
      this.handleParentForm(accessToken, osContext, state, selectedQuestionnaire, dispatch)
    } catch {
      dispatch(
        setState({
          ...state,
          error: true,
          initLoading: false,
        }),
      )
    }
  }
}
