import React from 'react'

import styles from 'app/components/header/Header.module.scss'
import AppStatus from 'app/components/review/appStatus'
import { WppDivider, WppGrid, WppTypography } from 'buildingBlocks'
import IApp from 'interfaces/app/IApp'
import { IReview } from 'interfaces/review/IReview'

interface IHeaderProps {
  /** App interface */
  app: IApp
  /** User review */
  review: IReview | null
  /** App action component */
  appActionComponent: React.ReactNode
}

/**
 * App header component
 * @param {object} props
 * @param {IApp} props.app
 * @param {IReview | null} props.review
 * @param {React.ReactNode} props.appActionComponent
 */
const Header: React.FC<IHeaderProps> = ({ app, review, appActionComponent }: IHeaderProps): React.ReactElement => {
  return (
    <div className={styles.headerContainer}>
      <WppGrid container>
        <WppGrid item all={24} className={styles.headerItem}>
          <div className={styles.header}>
            <WppTypography tag="h3" type="m-strong">
              {app.appName}
            </WppTypography>
            {review && <AppStatus appName={app.appName} review={review} />}
          </div>
          <div>{appActionComponent}</div>
        </WppGrid>
      </WppGrid>
      <WppDivider className={styles.divider} />
    </div>
  )
}

export default Header
