import React, { useState } from 'react'

import RejectModal from 'app/components/review//reject/rejectModal'
import ApproveModal from 'app/components/review/approve/approveModal'
import { WppButton } from 'buildingBlocks'
import { QuestionnaireStatus } from 'config/enums'

interface IReviewActionProps {
  /** Handle reviewer action*/
  handleReviewerAction: (statusValue: string, statusReason: string) => void
}

/**
 * ReviewAction component
 * @param {object} props
 * @param {(statusValue: string, statusReason: string) => void} props.handleReviewerAction
 */
const ReviewAction: React.FC<IReviewActionProps> = ({
  handleReviewerAction,
}: IReviewActionProps): React.ReactElement => {
  const [approveModal, setApproveModal] = useState<boolean>(false)
  const [rejectModal, setRejectModal] = useState<boolean>(false)

  const handleApproveModal = () => setApproveModal(!approveModal)
  const handleRejectModal = () => setRejectModal(!rejectModal)

  return (
    <>
      <WppButton
        id="btn-reject-form"
        size="s"
        data-testid="btn-reject-form"
        onClick={handleRejectModal}
        variant="secondary"
      >
        Reject form
      </WppButton>
      <WppButton size="s" id="btn-approve-form" data-testid="btn-approve-form" onClick={handleApproveModal}>
        Approve form
      </WppButton>
      <ApproveModal
        open={approveModal}
        onApprove={() => handleReviewerAction(QuestionnaireStatus.APPROVED, '')}
        handleModal={handleApproveModal}
      />
      <RejectModal
        open={rejectModal}
        onReject={(reason: string) => handleReviewerAction(QuestionnaireStatus.DRAFT, reason)}
        handleModal={handleRejectModal}
      />
    </>
  )
}

export default ReviewAction
