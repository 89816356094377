import config, { API_TYPES, COMMENTS_PER_PAGE } from 'config/constants'

const QUESTIONNAIRE_API: string = config[API_TYPES.QUESTIONNAIRE_API]
const PROJECT_API: string = config[API_TYPES.PROJECT_API]
const MASTER_API: string = config[API_TYPES.MASTER_API]
const FACADE_API: string = config[API_TYPES.FACADE_API]

export const saveFormDataAPIPath = () => `${config[API_TYPES.QUESTIONNAIRE_API]}/save_data`

export const getQuestionnaireAPIPath = (questionnaireId: string, projectId: string, tenantId: string, itemId: string) =>
  `${
    config[API_TYPES.QUESTIONNAIRE_API]
  }/questionnaire?questionnaire_id=${questionnaireId}&project_id=${projectId}&tenant_id=${tenantId}&item_id=${itemId}`

export const getQuestionnairesAPIPath = (tenantId: string): string => `${QUESTIONNAIRE_API}/questionnaire/${tenantId}`

export const getQuestionnaireByIdAPIPath = (id: string): string => `${QUESTIONNAIRE_API}/questionnaires/${id}`

export const getSelectedQuestionnaireAPIPath = (tenantId: string, projectId: string, itemId: string): string =>
  `${QUESTIONNAIRE_API}/project_questionnaire?project_id=${projectId}&tenant_id=${tenantId}&item_id=${itemId}`

export const getParentQuestionnairesAPIPath = (parentQuestionnaireId: string, projectId: string): string =>
  `${QUESTIONNAIRE_API}/project_questionnaires/reviewed?questionnaireId=${parentQuestionnaireId}&projectId=${projectId}&isParent=true`

export const getProjectAppsAPIPath = (projectId: string): string => `${PROJECT_API}/projects/${projectId}/tasks/search`

export const getUploadSignedAPIPath = (): string => `${PROJECT_API}/files/generate-temp-upload-urls`

export const getProjectMemberAPIPath = (projectId: string): string => `${PROJECT_API}/projects/${projectId}`

export const getUploadFileAPIPath = (projectId: string): string => `${PROJECT_API}/projects/${projectId}/files`

export const getReviewAPIPath = (projectQuestionnaireId: string) =>
  `${QUESTIONNAIRE_API}/reviews/${projectQuestionnaireId}`

export const sendToReviewAPIPath = (projectQuestionnaireId?: string) =>
  projectQuestionnaireId ? `${QUESTIONNAIRE_API}/reviews/${projectQuestionnaireId}` : `${QUESTIONNAIRE_API}/reviews`

export const updateReviewAPIPath = (projectQuestionnaireId: string) =>
  `${QUESTIONNAIRE_API}/reviews/${projectQuestionnaireId}/status`

export const deleteReviewAPIPath = (projectQuestionnaireId: string) =>
  `${QUESTIONNAIRE_API}/reviews/${projectQuestionnaireId}`

export const getMarketsAPIPath = () => `${MASTER_API}/markets?page=1&itemsPerPage=1000`

export const updateProjectQuestionnaireAPIPath = (projectQuestionnaireId: string) =>
  `${config[API_TYPES.QUESTIONNAIRE_API]}/project_questionnaire/${projectQuestionnaireId}`

export const createProjectQuestionnaireAPIPath = () => `${config[API_TYPES.QUESTIONNAIRE_API]}/project_questionnaire`

export const addCommentAPIPath = () => `${config[API_TYPES.QUESTIONNAIRE_API]}/comments`

export const getMoreCommentsByStartDateAPIPath = (
  projectQuestionnaireId: string,
  formFieldId: string,
  startDate: string,
  page: number,
) =>
  `${
    config[API_TYPES.QUESTIONNAIRE_API]
  }/project_questionnaires/${projectQuestionnaireId}/comments?formFieldId=${formFieldId}&createdAt%5Blte%5D=${startDate}&page=${page}&itemsPerPage=${COMMENTS_PER_PAGE}&sortOrder=desc`

export const getCommentUniqueUsersAPIPath = (projectQuestionnaireId: string, categoryId: string) =>
  `${
    config[API_TYPES.QUESTIONNAIRE_API]
  }/project_questionnaires/${projectQuestionnaireId}/comments/users?categoryId=${categoryId}&partitionBy=formFieldId`

export const getUserAPIPath = () => `${FACADE_API}/users`
