import _ from 'lodash'
import { useState } from 'react'
import { createPortal } from 'react-dom'

import styles from 'app/components/review/reject/rejectModal/RejectModal.module.scss'
import { WppButton, WppSideModal, WppTextareaInput } from 'buildingBlocks'
import { TextareaInputChangeEventDetail, WppTextareaInputCustomEvent } from 'utils/types'
interface IRejectModalProps {
  /** IApp interface */
  open: boolean
  /** Handle modal action*/
  handleModal: Function
  /** Handle reject action*/
  onReject: (reason: string) => void
}

/**
 * Approve Modal component
 * @param {object} props
 * @param {boolean} props.open
 * @param {MouseEventHandler<HTMLWppButtonElement>} props.handleModal
 * @param {(reason: string) => void} props.onReject
 */
const RejectModal: React.FC<IRejectModalProps> = ({
  open,
  handleModal,
  onReject,
}: IRejectModalProps): React.ReactElement => {
  const [reason, setReason] = useState<string>('')

  return (
    <>
      {createPortal(
        <WppSideModal
          size="m"
          open={open}
          data-testid="rejection-side-modal"
          onWppSideModalClose={() => handleModal()}
          onWppSideModalOpen={() => handleModal()}
        >
          <h3 slot="header">Reason for rejection</h3>
          <p slot="body">
            <WppTextareaInput
              name="reason-for-rejection"
              placeholder="ex. Kickstarter details need to be filled in."
              value={reason}
              charactersLimit={1000}
              id="reason-for-rejection"
              warningThreshold={900}
              data-testid="reason-for-rejection"
              onWppChange={(event: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) => {
                setReason(event.detail.value ?? '')
              }}
            />
          </p>
          <div slot="actions" className={styles.actions}>
            <WppButton data-testid="btn-cancel" variant="secondary" onClick={() => handleModal()}>
              Cancel
            </WppButton>
            <WppButton
              data-testid="btn-reject"
              onClick={() => onReject(reason)}
              disabled={!reason || _.gt(reason.length, 1000)}
            >
              Reject form
            </WppButton>
          </div>
        </WppSideModal>,
        document.body,
      )}
    </>
  )
}

export default RejectModal
