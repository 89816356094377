import { useOs } from '@wppopen/react'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import '@platform-ui-kit/components-library/dist/platform-ui-kit/global.css'

import styles from 'app/App.module.scss'
import config, { API_TYPES } from 'config/constants'
import QuestionnaireApplication from 'pages/Application'

export function App() {
  /**
   * This hook is provided by the utility package for more convenient work with OS context
   * @return osContext
   * - is a subscribe function that returns you the main context object with MicroAppContext data
   * @return osApi
   * - methods to fire actions on the parent OS level
   *
   * Read more about it in the README file
   */
  const { osContext } = useOs()

  console.log('[OS Context]', osContext)
  console.log('[MASTER_API]', config[API_TYPES.MASTER_API])
  console.log('[FACADE_API]', config[API_TYPES.FACADE_API])
  console.log('[PROJECT_API]', config[API_TYPES.PROJECT_API])
  console.log('[APP_NAMESPACE]', config[API_TYPES.APP_NAMESPACE])

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div id="app" className={styles.container}>
        <Routes>
          <Route index element={<QuestionnaireApplication />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}
