import { IQuestionnaireState } from 'interfaces/state/IQuestionnaireState'

export const defaultQuestionnaireState: IQuestionnaireState = {
  loading: false,
  app: null,
  appStatus: {
    isAppTouched: false,
    openModal: false,
  },
  error: false,
  activeStep: 1,
  rejectInfo: null,
  reviewers: [],
  review: null,
  enableCommentMode: false,
}
