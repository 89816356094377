// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jDOCX{display:flex;align-items:center;gap:.625rem}.X_5r2 .dJGdI{margin-top:8px;background:var(--wpp-white-color)}.X_5r2 .dJGdI .QBlj8{margin:1.5rem 2rem 2rem 2rem}.X_5r2 .dJGdI .QBlj8 .gfxxu{font-size:1.25rem;font-weight:600}.X_5r2 .dJGdI .QBlj8 .iA36N{margin-bottom:.5rem}.X_5r2 .dJGdI .QBlj8 .iA36N::part(typography){color:var(--typography-color) !important}.X_5r2 .dJGdI .QBlj8 .LrXyi{margin-top:1rem;line-height:1.375rem;font-size:.875rem;color:var(--grey-800, #4d5358);margin-bottom:1.5rem}.X_5r2 .hc1l0{display:flex;align-items:center;justify-content:space-between;width:100%;margin:2rem 0 1.5rem 0}`, "",{"version":3,"sources":["webpack://./src/pages/Application.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,WAAA,CAIA,cACE,cAAA,CACA,iCAAA,CAEA,qBACE,4BAAA,CACA,4BACE,iBAAA,CACA,eAAA,CAEF,4BACE,mBAAA,CACA,8CACE,wCAAA,CAGJ,4BACE,eAAA,CACA,oBAAA,CACA,iBAAA,CACA,8BAAA,CACA,oBAAA,CAIN,cACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,sBAAA","sourcesContent":[".actions {\n  display: flex;\n  align-items: center;\n  gap: 0.625rem;\n}\n\n.layout {\n  .layoutForm {\n    margin-top: 8px;\n    background: var(--wpp-white-color);\n\n    .form {\n      margin: 1.5rem 2rem 2rem 2rem;\n      .name {\n        font-size: 1.25rem;\n        font-weight: 600;\n      }\n      .label {\n        margin-bottom: 0.5rem;\n        &::part(typography) {\n          color: var(--typography-color) !important;\n        }\n      }\n      .description {\n        margin-top: 1rem;\n        line-height: 1.375rem;\n        font-size: 0.875rem;\n        color: var(--grey-800, #4d5358);\n        margin-bottom: 1.5rem;\n      }\n    }\n  }\n  .layoutHeader {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    margin: 2rem 0 1.5rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `jDOCX`,
	"layout": `X_5r2`,
	"layoutForm": `dJGdI`,
	"form": `QBlj8`,
	"name": `gfxxu`,
	"label": `iA36N`,
	"description": `LrXyi`,
	"layoutHeader": `hc1l0`
};
export default ___CSS_LOADER_EXPORT___;
