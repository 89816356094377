import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import styles from 'app/components/form/formField/formFieldComment/FormFieldComment.module.scss'
import { WppActionButton, WppAvatar, WppTextareaInput, WppTypography } from 'buildingBlocks'
import { TEXT_AREA_CHARACTER_LIMIT, TEXT_AREA_WARNING_THRESHOLD } from 'config/enums'
import IField from 'interfaces/field/IField'
import { TextareaInputChangeEventDetail, WppTextareaInputCustomEvent } from 'utils/types'

interface IFormFieldCommentEditorProps {
  /** Field object */
  field: IField
  /** Handle comment data */
  handleComment: (field: IField, value: string) => void
  /** Popover ref */
  popoverRef: React.RefObject<HTMLWppPopoverElement>
}

/**
 * Create label for the field
 * @param {object} props
 * @param {IField} props.field
 * @param {React.RefObject<HTMLWppPopoverElement>} props.popoverRef
 * @param {(field: IField, value: string) => void} props.handleComment
 */
const FormFieldCommentEditor: React.FC<IFormFieldCommentEditorProps> = ({
  field,
  handleComment,
  popoverRef,
}: IFormFieldCommentEditorProps): React.ReactElement => {
  const { osContext } = useOs()
  const cancelRef: React.RefObject<HTMLWppActionButtonElement> = useRef<HTMLWppActionButtonElement>(null)
  const addCommentRef: React.RefObject<HTMLWppActionButtonElement> = useRef<HTMLWppActionButtonElement>(null)
  const [value, setValue] = useState<string>('')
  const { firstname, lastname, avatarUrl } = osContext.userDetails

  const onCancel = (event: Event) => {
    event.preventDefault()
    popoverRef.current?.closePopover()
  }

  const onAddComment = (event: Event) => {
    event.preventDefault()
    handleComment(field, value)
    setValue('')
  }

  useEffect(() => {
    if (cancelRef.current) {
      cancelRef.current.addEventListener('click', onCancel)
    }
    if (addCommentRef.current) {
      addCommentRef.current.addEventListener('click', onAddComment)
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancelRef.current?.removeEventListener('click', onCancel)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      addCommentRef.current?.removeEventListener('click', onAddComment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className={styles.commentEditor}>
      <div className={styles.userInfo}>
        <WppAvatar name={`${firstname} ${lastname}`} withTooltip src={avatarUrl ?? ''} />
        <WppTypography type="s-strong" tag="p">
          {`${firstname} ${lastname}`}
        </WppTypography>
      </div>
      <WppTextareaInput
        name="comment"
        placeholder="Comment"
        data-testid="textarea-comment"
        value={value}
        charactersLimit={TEXT_AREA_CHARACTER_LIMIT}
        warningThreshold={TEXT_AREA_WARNING_THRESHOLD}
        onWppChange={(event: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) =>
          setValue(_.toString(event.detail.value))
        }
      />
      <div className={styles.actions}>
        <WppActionButton data-testid="btn-cancel-comment" ref={cancelRef} variant="secondary">
          Cancel
        </WppActionButton>
        <WppActionButton
          data-testid="btn-add-comment"
          ref={addCommentRef}
          disabled={_.isEmpty(_.toString(value).trim()) || _.gt(value.length, 1000)}
        >
          Add comment
        </WppActionButton>
      </div>
    </div>
  )
}

export default FormFieldCommentEditor
