// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.evxJs::part(message){font-size:14px}`, "",{"version":3,"sources":["webpack://./src/app/components/review/appStatus/AppStatus.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".inlineMessage::part(message) {\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineMessage": `evxJs`
};
export default ___CSS_LOADER_EXPORT___;
