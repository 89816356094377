import axios, { AxiosInstance, AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios'
import axiosRetry from 'axios-retry'

export const axiosInstance: AxiosInstance = axios.create()

class AxiosService {
  private accessToken: string
  constructor(accessToken: string, retryCount: number = 5, retryTime: number = 2000) {
    this.accessToken = accessToken
    axiosRetry(axiosInstance, {
      retryDelay: (retryCount: number) => retryCount * retryTime,
      retries: retryCount,
    })
  }

  private getRequestHeader = (tenantId: string, headers?: any): RawAxiosRequestHeaders => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this.accessToken}`,
    'X-Tenant-Id': tenantId,
    ...headers,
  })

  public get<T>(url: string, tenantId: string): Promise<T> {
    return axiosInstance.get(url, {
      headers: this.getRequestHeader(tenantId),
    })
  }

  public post<T>(url: string, data: any, tenantId: string, config?: AxiosRequestConfig): Promise<T> {
    return axiosInstance.post(url, data, {
      headers: this.getRequestHeader(tenantId, config?.headers),
    })
  }

  public put<T>(url: string, data: any, tenantId: string, config?: AxiosRequestConfig): Promise<T> {
    return axiosInstance.put(url, data, {
      headers: this.getRequestHeader(tenantId, config?.headers),
    })
  }

  public patch<T>(url: string, data: any, tenantId: string, config?: AxiosRequestConfig): Promise<T> {
    return axiosInstance.patch(url, data, {
      headers: this.getRequestHeader(tenantId, config?.headers),
    })
  }

  public delete<T>(url: string, tenantId: string, config?: AxiosRequestConfig): Promise<T> {
    return axiosInstance.delete(url, {
      headers: this.getRequestHeader(tenantId, config?.headers),
    })
  }
}

export default AxiosService
