import { FullscreenAppCustomProps } from '@wppopen/core'
import { OsProvider } from '@wppopen/react'
import { StrictMode } from 'react'
import '@platform-ui-kit/components-library/dist/platform-ui-kit/global.css'

import { App } from 'app/App'

export const Root = (props: FullscreenAppCustomProps) => {
  return (
    <StrictMode>
      <OsProvider {...props}>
        <App />
      </OsProvider>
    </StrictMode>
  )
}
