import { MouseEventHandler } from 'react'

import styles from 'app/components/review/approve/approveModal/ApproveModal.module.scss'
import { WppButton, WppModal } from 'buildingBlocks'

interface IApproveModalProps {
  /** IApp interface */
  open: boolean
  /** Handle modal action*/
  handleModal: MouseEventHandler<HTMLWppButtonElement>
  /** Handle approve action*/
  onApprove: MouseEventHandler<HTMLWppButtonElement>
}

/**
 * Approve Modal component
 * @param {object} props
 * @param {boolean} props.open
 * @param {MouseEventHandler<HTMLWppButtonElement>} props.handleModal
 * @param {MouseEventHandler<HTMLWppButtonElement>} props.onApprove
 */
const ApproveModal: React.FC<IApproveModalProps> = ({
  open,
  handleModal,
  onApprove,
}: IApproveModalProps): React.ReactElement => {
  return (
    <WppModal open={open}>
      <h3 slot="header">Approve form?</h3>
      <p slot="body">The form cannot be changed after approval.</p>
      <div slot="actions" className={styles.actions}>
        <WppButton size="s" variant="secondary" onClick={handleModal}>
          Cancel
        </WppButton>
        <WppButton size="s" onClick={onApprove}>
          Approve
        </WppButton>
      </div>
    </WppModal>
  )
}

export default ApproveModal
