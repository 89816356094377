import { WppRadioGroupCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import FormFieldChildren from 'app/components/form/formField/formFieldChildren'
import FormFieldDescription from 'app/components/form/formField/formFieldDescription'
import FormFieldMessage from 'app/components/form/formField/formFieldMessage'
import styles from 'app/components/form/formField/formFieldRadio/FormFieldRadio.module.scss'
import Radio from 'app/components/form/formField/formFieldRadio/Helper/Radio'
import { WppRadioGroup } from 'buildingBlocks'
import { Orientation } from 'config/enums'
import IOption from 'interfaces/common/IOption'
import { IFieldComment } from 'interfaces/field/fieldComment/IFieldComment'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { IReview } from 'interfaces/review/IReview'
import { RadioGroupChangeEvent } from 'utils/types'

interface IFormFieldRadioProps {
  /** IField interface */
  field: IField
  /** Review Object */
  review: IReview | null
  /** Is comment mode enabled*/
  enableCommentMode: boolean
  /** If user is project member*/
  isProjectMember: boolean
  /** handle input change event. It has InputFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Handle field comment */
  handleComment: (field: IField, value: string) => void
  /** Form Field comments */
  setFormFieldComments: (field: IField, fieldComment: IFieldComment) => void
}

/**
 * Create radio field
 * @param {object} props
 * @param {IField} props.field
 * @param {IReview} props.review
 * @param {boolean} props.enableCommentMode
 * @param {boolean} props.isProjectMember
 * @param {(event: IFieldChangeEvent) => void} props.handleChange handle input field change event
 * @param {(field: IField, value: string) => void} props.handleComment Handle field comment
 * @param {(field: IField, fieldComment: IFieldComment) => void} props.setFormFieldComments
 */
const FormFieldRadio: React.FC<IFormFieldRadioProps> = ({
  field,
  handleChange,
  handleComment,
  enableCommentMode,
  isProjectMember,
  review,
  setFormFieldComments,
}: IFormFieldRadioProps): React.ReactElement => {
  const { fieldConfig, value } = field
  const { options, orientation } = fieldConfig

  return (
    <>
      <WppRadioGroup
        className={classNames(
          {
            [styles.displayRadioGroupVertical]: _.isEqual(orientation, Orientation.VERTICAL),
            [styles.displayRadioGroupHorizontal]: _.isEqual(orientation, Orientation.HORIZONTAL),
          },
          styles.radioGroup,
        )}
        onWppChange={(event: WppRadioGroupCustomEvent<RadioGroupChangeEvent>) => {
          handleChange({
            field,
            value: event.detail.value,
          })
        }}
        value={value}
        id={`radio-group-${field.id}`}
      >
        {options.map((option: IOption) => (
          <div className={styles.container} key={option.id}>
            <div className={styles.radioContainer}>
              <Radio field={field} handleChange={handleChange} option={option} />
              <FormFieldDescription
                field={{
                  ...field,
                  value,
                }}
                option={option}
              />
            </div>
            <FormFieldChildren
              field={{
                ...field,
                value,
              }}
              isProjectMember={isProjectMember}
              optionValue={option.id}
              handleChange={handleChange}
              handleComment={handleComment}
              review={review}
              enableCommentMode={enableCommentMode}
              setFormFieldComments={setFormFieldComments}
            />
          </div>
        ))}
      </WppRadioGroup>

      <FormFieldMessage field={field} />
    </>
  )
}

export default FormFieldRadio
