import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useEffect, useReducer } from 'react'

import Confirmation from 'app/components/confirmation'
import Questionnaires from 'app/components/questionnaires'
import Spinner from 'app/components/spinner/Spinner'
import Toast from 'app/components/toast'
import { WppButton, WppGrid, WppTypography } from 'buildingBlocks'
import useToast from 'hooks'
import IOption from 'interfaces/common/IOption'
import ApplicationError from 'pages/appError'
import styles from 'pages/Application.module.scss'
import Questionnaire from 'pages/questionnaire/Questionnaire'
import { questionnairesReducer, setOpenModal, setParentQuestionnaireItem } from 'pages/store/actions'
import { defaultQuestionnairesState } from 'pages/store/state'
import Unauthorized from 'pages/unauthorized'
import ApplicationHelper from 'utils/form/ApplicationHelper'

/**
 * Application
 */
const Application: React.FC = (): React.ReactElement => {
  const { osContext, osApi } = useOs()
  const [state, dispatch] = useReducer(questionnairesReducer, defaultQuestionnairesState)

  const {
    error,
    initLoading,
    loading,
    openModal,
    parentQuestionnaireItemId,
    parentQuestionnaires,
    questionnaireId,
    questionnaires,
    showQuestionnaireSelection,
    disableFormSelection,
    projectQuestionnaireId,
    unauthorizedApp,
    unauthorizedQuestionnaire,
  } = state
  const { showToast } = useToast()
  const accessToken: string = osApi.getAccessToken()

  const handleQuestionnaireSelection = (option: IOption) => {
    ApplicationHelper.handleQuestionnaireSelection(accessToken, osContext, option, state, dispatch)
  }

  const handleParentQuestionnaireSelection = (option: IOption) => {
    dispatch(setParentQuestionnaireItem(option.id))
  }

  const onSaveProjectQuestionnaire = async () => {
    ApplicationHelper.onSaveProjectQuestionnaire(accessToken, osContext, state, dispatch, showToast)
  }

  const initLoad = async () => {
    ApplicationHelper.initLoad(accessToken, state, osContext, dispatch)
  }

  useEffect(() => {
    initLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <ApplicationError />
  }

  if (unauthorizedApp) {
    return (
      <Unauthorized message="You are not Authorized to view Project Brief. Please open a support ticket to get access." />
    )
  }

  if (!_.isEmpty(unauthorizedQuestionnaire)) {
    return <Unauthorized message={unauthorizedQuestionnaire} />
  }

  return (
    <>
      <Confirmation
        title="Save this from?"
        body="Once you select a form and save, your choice will be final and cannot be changed. Please make sure to choose the correct form for your project"
        isOpen={openModal}
        handlePrimaryAction={onSaveProjectQuestionnaire}
        handleSecondaryAction={() => dispatch(setOpenModal(false))}
      />
      {(initLoading || loading) && <Spinner />}
      {!initLoading && showQuestionnaireSelection && (
        <WppGrid container className={styles.layout} rowSpacing={2}>
          <WppGrid item all={24} className={styles.layoutHeader}>
            <WppTypography tag="h1" type="2xl-heading">
              Project Requirements Form
            </WppTypography>
          </WppGrid>
          <WppGrid className={styles.layoutForm} item all={24}>
            <WppGrid container className={styles.form}>
              <WppGrid item all={8}>
                <WppTypography className={styles.name} tag="h2" type="xl-heading">
                  Select the form for your project
                </WppTypography>
                <p className={styles.description}>
                  Choose the appropriate form for your project from the options below
                </p>
                <Questionnaires
                  parentQuestionnaires={parentQuestionnaires}
                  questionnaires={questionnaires}
                  handleParentQuestionnaireSelection={handleParentQuestionnaireSelection}
                  handleQuestionnaireSelection={handleQuestionnaireSelection}
                  parentQuestionnaireItemId={parentQuestionnaireItemId}
                  questionnaireId={questionnaireId}
                  disableFormSelection={disableFormSelection}
                />
                <WppButton
                  disabled={!questionnaireId || (!_.isEmpty(parentQuestionnaires) && !parentQuestionnaireItemId)}
                  id="btn-save"
                  onClick={() => dispatch(setOpenModal(true))}
                >
                  Save
                </WppButton>
              </WppGrid>
            </WppGrid>
          </WppGrid>
        </WppGrid>
      )}
      {!initLoading &&
        !_.isNull(projectQuestionnaireId) &&
        !_.isNull(questionnaireId) &&
        !showQuestionnaireSelection && (
          <Questionnaire projectQuestionnaireId={projectQuestionnaireId} questionnaireId={questionnaireId} />
        )}
      <Toast />
    </>
  )
}

export default Application
