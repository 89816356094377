import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { FieldType } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICompletionRate from 'interfaces/app/ICompletionRate'
import ICategory from 'interfaces/category/ICategory'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { IRepeatableField } from 'interfaces/field/repeatable/IRepeatableField'
import IForm from 'interfaces/form/IForm'
import FileHelper from 'utils/file/FileHelper'
import FormFieldValidation from 'utils/formField/FormFieldValidation'
import SharedHelper from 'utils/SharedHelper'

export default class FormFieldHelper {
  private formFieldValidation: FormFieldValidation
  private sharedHelper: SharedHelper
  constructor() {
    this.formFieldValidation = new FormFieldValidation()
    this.sharedHelper = new SharedHelper()
  }

  /**
   * Validate field value change
   * @param {IFieldChangeEvent} event
   * @returns {boolean}
   */
  isValidChange(event: IFieldChangeEvent): boolean {
    const { field, value } = event
    if (_.gt(field.maxRepeatableAmount, 0)) {
      return true
    }

    let isValidChange = false

    switch (field.type) {
      case FieldType.COUNTER:
      case FieldType.RADIO:
      case FieldType.TEXT_INPUT:
      case FieldType.DATE_PICKER:
      case FieldType.NUMBER:
      case FieldType.TEXT_AREA: {
        isValidChange = !_.isEqual(value, field.value)
        break
      }
      case FieldType.FILE_UPLOAD: {
        isValidChange = true
        break
      }
      case FieldType.CURRENCY: {
        if (!value) {
          isValidChange = true
        } else {
          isValidChange =
            !_.isEqual(value.currencyType, field.value.currencyType) ||
            !_.isEqual(value.currencyAmount, field.value.currencyAmount)
        }
        break
      }
      // Select/Market Field
      case FieldType.SELECT:
      case FieldType.MARKET: {
        isValidChange = field.fieldConfig.multi
          ? !_.isEmpty(this.sharedHelper.isDiffArray(value, field.value))
          : !_.isEqual(value, field.value)
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.AUTOCOMPLETE:
      case FieldType.USER: {
        isValidChange = !_.isEmpty(this.sharedHelper.isDiffArray(value, field.value))
        break
      }
    }
    return isValidChange
  }

  /**
   * Get default value
   * @param {IField} field
   * @returns {any}
   */
  getDefaultValue(field: IField): any {
    if (_.gt(field.maxRepeatableAmount, 0)) {
      return []
    }
    let value = null
    switch (field.type) {
      case FieldType.TEXT_INPUT:
      case FieldType.TEXT_AREA: {
        value = ''
        break
      }
      // Select/Market Field
      case FieldType.SELECT:
      case FieldType.MARKET: {
        value = field.fieldConfig.multi ? [] : null
        break
      }
      case FieldType.CHECKBOX:
      case FieldType.FILE_UPLOAD:
      case FieldType.AUTOCOMPLETE:
      case FieldType.USER: {
        value = []
        break
      }
    }
    return value
  }

  /**
   * Update form repeatable field
   * @param {IFieldChangeEvent} event
   * @returns {{ repeatableFields: IRepeatableField[]; isValid: boolean }}
   */
  updateRepeatableField = (
    field: IField,
    event: IFieldChangeEvent,
  ): { repeatableFields: IRepeatableField[]; isValid: boolean } => {
    let repeatableFields: IRepeatableField[] = [...event.field.repeatableFields]
    let isValid = true
    if (event.repeatableEventType) {
      if (_.isEqual(event.repeatableEventType, 'remove')) {
        repeatableFields = repeatableFields.filter(
          (repeatableField: IRepeatableField) => !_.isEqual(repeatableField.id, event.repeatableFieldId),
        )
      } else {
        repeatableFields = [
          ...repeatableFields,
          {
            id: uuidv4(),
            value: '',
            order: _.last(repeatableFields)?.order ?? repeatableFields.length + 1,
            isValid: true,
            errorMessage: '',
            isCompleted: false,
            touched: false,
          },
        ]
      }

      repeatableFields = repeatableFields.map((repeatableField: IRepeatableField) => {
        let rField = this.formFieldValidation.validate(field, repeatableField.value)
        let reIsValid = repeatableField.touched ? rField.isValid : true
        isValid = isValid && reIsValid

        return {
          ...repeatableField,
          isValid: rField.isValid,
        }
      })

      return {
        repeatableFields,
        isValid,
      }
    }

    return {
      repeatableFields: repeatableFields.map((repeatableField: IRepeatableField): IRepeatableField => {
        if (_.isEqual(repeatableField.id, event.repeatableFieldId)) {
          let rField = this.formFieldValidation.validate(field, event.value)
          isValid = isValid && rField.isValid
          let isCompleted = !FormFieldValidation.isEmptyField({ ...field, value: event.value }) && rField.isValid

          return {
            ...repeatableField,
            value: event.value,
            isValid: rField.isValid,
            errorMessage: rField.errorMessage,
            touched: true,
            isCompleted,
          }
        }
        let reIsValid = repeatableField.touched ? repeatableField.isValid : true
        isValid = isValid && reIsValid
        return repeatableField
      }),
      isValid,
    }
  }

  /**
   * Update an field
   * @param {IField} field
   * @param {InputFieldChangeEvent} event
   * @returns {IField}
   */
  updateField(field: IField, event: IFieldChangeEvent): IField {
    if (_.isEqual(field.id, event.field.id)) {
      let updatedField = this.formFieldValidation.validate(field, event.value)
      let isValid = updatedField.isValid
      let value = event.value
      let touched = true
      let repeatableFields: IRepeatableField[] = field.repeatableFields
      if (_.gt(field.maxRepeatableAmount, 0) && (event.repeatableFieldId || event.repeatableEventType)) {
        let update = this.updateRepeatableField(field, event)
        repeatableFields = update.repeatableFields
        isValid = update.isValid
        value = repeatableFields

        let rFieldTouched = false
        repeatableFields.forEach((repeatableField: IRepeatableField) => {
          rFieldTouched = rFieldTouched || repeatableField.touched
        })
        touched = rFieldTouched
      }

      updatedField = {
        ...updatedField,
        value: value,
        touched,
        isValid: isValid && !event.hasError,
        repeatableFields,
      }

      updatedField = {
        ...updatedField,
        isCompleted: !FormFieldValidation.isEmptyField(updatedField) && updatedField.isValid,
      }

      if (isValid) {
        const { messageType, ...rest } = updatedField
        return rest
      }

      return {
        ...updatedField,
        messageType: 'error',
      }
    }

    return {
      ...field,
      children: field.children.map((childField: IField) => {
        return this.updateField(childField, event)
      }),
    }
  }

  /**
   * Pre-save check category for file upload
   * @param {IField} field
   * @param {string} accessToken
   * @param {string} tenantId
   * @param {string} projectId
   * @returns {Promise<IField>}
   */
  async preSaveFieldCheck(
    field: IField,
    accessToken: string,
    tenantId: string,
    tenantUrl: string,
    projectId: string,
  ): Promise<IField> {
    const Field: IField = {
      ...field,
    }
    const Value = Field.value
    if (_.isEqual(Field.type, FieldType.FILE_UPLOAD) && field.isValid) {
      const fileHelper = new FileHelper(accessToken)
      const UpdatedFiles = []

      for (let value of Value) {
        if (!value.id && !value.file.formatError) {
          const UpdatedFile = await fileHelper.uploadFile(value.file, tenantId, tenantUrl, projectId)
          UpdatedFiles.push(UpdatedFile)
        } else {
          UpdatedFiles.push(value)
        }
      }
      Field.value = UpdatedFiles
    }
    return Field
  }

  /**
   * Get field children
   * @param {IField} field
   * @param {any} value
   * @returns {IField[]}
   */

  getChildren(field: IField, value: any): IField[] {
    if (_.isEqual(field.type, FieldType.CHECKBOX)) {
      const Values = value.map((option: IOption) => _.toString(option.id))
      return field.children.filter((childField: IField) => {
        return this.sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, Values)
      })
    }

    return field.children.filter((childField: IField) =>
      this.sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(value)]),
    )
  }

  /**
   * Get field by id
   * @param {ICategory[]} field
   * @param {string} fieldId
   * @returns {IField}
   */
  findFieldById(categories: ICategory[], fieldId: string): IField | null {
    let field = null
    categories.forEach((category: ICategory) => {
      category.forms.forEach((form: IForm) => {
        form.fields.forEach((fieldParam: IField) => {
          if (_.isEqual(fieldParam.id, fieldId)) {
            field = fieldParam
          }
        })
      })
    })
    return field
  }

  /**
   * Get fields in object
   * @param {ICategory[]} field
   * @param {boolean} isEmpty
   * @returns {IField}
   */
  getFormFieldsObject(categories: ICategory[], isEmpty?: boolean): { [key: string]: string } {
    let field: { [key: string]: string } = {}
    categories.forEach((category: ICategory) => {
      category.forms.forEach((form: IForm) => {
        form.fields.forEach((fieldParam: IField) => {
          field[_.toLower(fieldParam.fieldConfig.text)] = isEmpty ? '' : fieldParam.value
        })
      })
    })
    return field
  }

  /**
   * Get field by form field id
   * @param {ICategory[]} field
   * @param {string} fieldId
   * @returns {IField[]}
   */
  findFieldByFormFieldId(categories: ICategory[], formFieldId: string): IField | null {
    let field = null
    categories.forEach((category: ICategory) => {
      category.forms.forEach((form: IForm) => {
        form.fields.forEach((fieldParam: IField) => {
          if (_.isEqual(fieldParam.formFieldId, formFieldId)) {
            field = fieldParam
          }
        })
      })
    })
    return field
  }

  /**
   * If the field is hidden
   * @param {IField} parentField
   * @param {IField} childField
   * @returns {boolean}
   */
  isFieldHidden(parentField: IField, childField: IField): boolean {
    if (_.isArray(parentField.value)) {
      const IsValidValue = parentField.value.findIndex((option: IOption) =>
        this.sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(option.id)]),
      )
      return _.isEqual(IsValidValue, -1)
    }
    return !this.sharedHelper.findAnyArrayValueInTargetArray(childField.visibleOnValue, [_.toString(parentField.value)])
  }

  /**
   * Count fields in the application
   * @param {IField} field
   * @returns {{ count: number; completed: number }}
   */
  getTotalAndCompletionFields(field: IField): { count: number; completed: number } {
    let count = 0
    let completed = 0

    if (!field.isHidden) {
      field.children.forEach((childField: IField) => {
        const childFieldData = this.getTotalAndCompletionFields(childField)
        count += childFieldData.count
        completed += childFieldData.completed
      })
      if (!_.isEmpty(field.repeatableFields)) {
        let repeatableFields = 0
        field.repeatableFields.forEach((repeatableField: IRepeatableField) => {
          const isEmpty = FormFieldValidation.isEmptyField({
            ...field,
            value: repeatableField.value,
          })
          completed = isEmpty ? completed : completed + 1
          repeatableFields += 1
        })
        count += repeatableFields
      } else {
        count += 1
        completed += FormFieldValidation.isEmptyField(field) ? completed : 1
      }
    }

    return {
      count,
      completed,
    }
  }

  /**
   * Count total no of field fields
   * @param {IApp} app
   * @returns {ICompletionRate}
   */
  getCompletionRate(app: IApp): ICompletionRate {
    let totalCompleted = 0
    let totalCount = 0

    app.categories.forEach((category: ICategory) => {
      category.forms.forEach((form: IForm) => {
        form.fields.forEach((field: IField) => {
          const { completed, count } = this.getTotalAndCompletionFields(field)
          totalCount += count
          totalCompleted += completed
        })
      })
    })
    return {
      completed: totalCompleted,
      total: totalCount,
      percentage: _.isEqual(totalCompleted, 0) ? 1 : Math.round((totalCompleted / totalCount) * 100),
    }
  }
}
