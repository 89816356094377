import { useOs } from '@wppopen/react'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import styles from 'app/components/review/approve/approveModal/ApproveModal.module.scss'
import { WppButton, WppModal, WppTooltip } from 'buildingBlocks'
import { ProjectRole } from 'config/enums'
import { IReviewer } from 'interfaces/review/IReviewer'

interface ICancelReviewProps {
  /** Reviewers data */
  reviewers: IReviewer[]
  /** Handle cancel review action*/
  handleCancelReview: Function
}

/**
 * Cancel Review component
 * @param {object} props
 * @param {IReviewer[]} props.reviewers
 * @param {Function} props.handleCancelReview
 */
const CancelReview: React.FC<ICancelReviewProps> = ({
  handleCancelReview,
  reviewers,
}: ICancelReviewProps): React.ReactElement => {
  const [open, setOpen] = useState(false)
  const { osContext } = useOs()
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    const reviewer: IReviewer | undefined = reviewers.find((reviewer: IReviewer) =>
      _.isEqual(_.toLower(reviewer.email), _.toLower(osContext.userDetails.email)),
    )
    if (reviewer && _.isEqual(reviewer.role, ProjectRole.OWNER)) {
      setAuthorized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osContext.userDetails.email])

  const handleModal = () => setOpen((prevState: boolean) => !prevState)

  return (
    <>
      <WppTooltip
        text="You are not authorized to perform this action. Required Project Role : OWNER"
        component={
          <WppButton
            id="btn-cancel-review"
            variant="destructive"
            disabled={!authorized}
            data-testid="btn-cancel-review"
            onClick={handleModal}
            size="s"
          >
            Cancel Review
          </WppButton>
        }
        showTooltip={!authorized}
      />
      <WppModal open={open}>
        <h3 slot="header">Cancel review?</h3>
        <p slot="body">The review request will be canceled.</p>
        <div slot="actions" className={styles.actions}>
          <WppButton size="s" id="btn-cancel" data-testid="btn-cancel" variant="secondary" onClick={handleModal}>
            No
          </WppButton>
          <WppButton
            variant="destructive"
            id="btn-continue"
            data-testid="btn-continue"
            size="s"
            onClick={() => {
              handleCancelReview()
              handleModal()
            }}
          >
            Yes
          </WppButton>
        </div>
      </WppModal>
    </>
  )
}

export default CancelReview
